import { AvatarSize } from "@components/Avatar";
import { NonprofitAvatar } from "@components/Avatar/NonprofitAvatar";
import { ButtonRole, ButtonSize, fullWidthButtonCss } from "@components/Button";
import { HeaderCard } from "@components/Card";
import { FundShareButton } from "@components/FundShareButton";
import { Icon, IconDisplay, IconSize } from "@components/Icon";
import { NonprofitIdLink } from "@components/NonprofitLink";
import { HeaderCardTitle } from "@components/PageHeaderCard/HeaderCardTitle";
import Popover from "@components/Popover/component";
import {
  HeaderCardMetadataModalButton,
  LayoutDirection,
} from "@components/Profile/HeaderCardInfo";
import { headerMetaWrapperStyles } from "@components/ProfilePageHeaderCard";
import { NonprofitSupporters } from "@components/SupportersList/NonprofitSupporters";
import { UserIdLink } from "@components/UserLink";
import { LikeButtonWithCounter } from "@components/feed/LikeButton";
import { DonateButton } from "@components/layout/DonateButton";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useMemo } from "react";

import {
  LikeableType,
  FundCreatingEntityType,
  NonprofitType,
} from "@every.org/common/src/entity/types";
import { constructCloudinaryUrl } from "@every.org/common/src/helpers/cloudinary";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { LinkAppearance } from "src/styles/link";
import { colorCssVars } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import {
  spacing,
  horizontalStackCss,
  verticalStackCss,
} from "src/theme/spacing";

type FundHeaderCardProps = {
  fund: ContextNonprofit;
};

const metadata = (fund: ContextNonprofit) => (
  <div css={headerMetaWrapperStyles}>
    <div css={[horizontalStackCss.xs, { alignItems: "center" }]}>
      <FundShareButton
        fund={fund}
        data-tname="shareFundButton"
        hideText
        role={ButtonRole.TEXT_ONLY}
      />
      <LikeButtonWithCounter
        data-tname="likeButton"
        role={ButtonRole.TEXT_ONLY}
        size={ButtonSize.SMALL}
        type={LikeableType.FUND}
        id={fund.id}
        likeCount={fund.likesInfo?.count || null}
        loggedInUserLikes={fund.likesInfo?.hasLoggedInUserLiked || null}
      />
    </div>
  </div>
);

const TitleSection = styled.section``; // just for the selector

export const FundHeaderCard: React.FCC<FundHeaderCardProps> = ({ fund }) => {
  const {
    id,
    name,
    description,
    supporterCount,
    fundCreatingEntityId,
    fundCreatingEntityType,
  } = fund;

  const creatingEntityLink = useMemo(
    function getCreatingEntityLink() {
      if (!fundCreatingEntityId || !fundCreatingEntityType) {
        return null;
      }

      if (fundCreatingEntityType === FundCreatingEntityType.USER) {
        return (
          <UserIdLink
            userId={fundCreatingEntityId}
            appearance={LinkAppearance.HYPERLINK_UNCOLORED}
          />
        );
      }
      return (
        <NonprofitIdLink
          nonprofitId={fundCreatingEntityId}
          appearance={LinkAppearance.HYPERLINK_UNCOLORED}
          data-tname={"fund-nonprofitCreatorLink"}
        />
      );
    },
    [fundCreatingEntityId, fundCreatingEntityType]
  );

  const actionComponents = metadata(fund);

  const popoverText =
    fund.type === NonprofitType.LIST ? (
      <span>
        Your donation will be split between the nonprofits listed below.
        Every.org and {creatingEntityLink} do not keep anything for themselves.
      </span>
    ) : (
      <span>
        Your donation will be sent to {creatingEntityLink} to support nonprofits
        listed below or other registered nonprofits with a similar mission.
        Every.org does not keep anything for itself.
      </span>
    );

  return (
    <HeaderCard
      css={css`
        display: flex;
        flex-direction: row;
        padding: 0;
      `}
    >
      <div
        css={[
          verticalStackCss.m,
          css`
            margin: ${spacing.xxl};
            flex: 1;
          `,
        ]}
      >
        <TitleSection
          css={cssForMediaSize({
            min: MediaSize.LARGE,
            css: [
              horizontalStackCss.m,
              css`
                justify-content: space-between;
                margin-left: 0;
              `,
            ],
          })}
        >
          <section
            css={css`
              flex-grow: 1;
            `}
          >
            <HeaderCardTitle
              title={name}
              subtitle={
                creatingEntityLink ? (
                  <div
                    css={[
                      horizontalStackCss.xxs,
                      css`
                        color: var(${colorCssVars.text.secondary});
                        align-items: center;
                      `,
                    ]}
                  >
                    <p
                      css={css`
                        display: flex;
                        flex-wrap: wrap;
                      `}
                    >
                      By&nbsp;{creatingEntityLink}
                    </p>
                    <Popover
                      placement={"bottom"}
                      css={css`
                        cursor: pointer;
                      `}
                      contentCss={css`
                        max-width: 300px;
                        a {
                          display: inline;
                        }
                      `}
                      content={popoverText}
                    >
                      <Icon
                        iconImport={() =>
                          import("@components/Icon/icons/InfoIcon")
                        }
                        display={IconDisplay.SECONDARY}
                        size={IconSize.X_SMALL}
                      />
                    </Popover>
                  </div>
                ) : undefined
              }
              avatar={(avatarCss) => (
                <NonprofitAvatar
                  nonprofit={fund}
                  size={AvatarSize.MEDIUM}
                  disableLink
                  customCSS={avatarCss}
                />
              )}
            />
          </section>
        </TitleSection>
        {supporterCount || actionComponents ? (
          <div
            css={[
              horizontalStackCss.m,
              css`
                border-top: 1px solid var(${colorCssVars.dividerSoft});
                padding-top: ${spacing.m};
              `,
            ]}
          >
            {supporterCount ? (
              <dl>
                <HeaderCardMetadataModalButton
                  modalButtonProps={{
                    buttonProps: {
                      "data-tname": "viewFundSupportersButton",
                      role: ButtonRole.UNSTYLED,
                    },
                    modalHeader: "Donors",
                    modalContentLabel: "Donors Modal",
                    modalContent: function NonprofitSupportersModalContent(
                      setIsModalOpen
                    ) {
                      return (
                        <NonprofitSupporters
                          nonprofitId={id}
                          supporterCount={supporterCount}
                          onClick={() => {
                            setIsModalOpen(false);
                          }}
                        />
                      );
                    },
                  }}
                  headerCardMetadataProps={{
                    data: supporterCount,
                    label: supporterCount > 1 ? "Donors" : "Donor",
                    layoutDirection: LayoutDirection.HORIZONTAL,
                    microdataInteractionType: "https://schema.org/DonateAction",
                  }}
                />
              </dl>
            ) : null}

            {actionComponents && (
              <section
                css={css`
                  margin-left: auto;
                `}
              >
                {actionComponents}
              </section>
            )}
          </div>
        ) : null}
        {/* Body */}
        <div>
          <p>{description}</p>
        </div>
        {/* Main CTA */}
        <div
          css={[
            css`
              width: 100%;
              ${verticalStackCss.l}
            `,
          ]}
        >
          <div
            css={css`
              margin-left: auto;
            `}
          >
            <DonateButton
              data-tname="startDonateButton"
              primarySlug={fund.primarySlug}
              donationsEnabled={fund.donationsEnabled}
              size={ButtonSize.MEDIUM}
              css={fullWidthButtonCss}
              toNonprofitPage
            >
              Donate
            </DonateButton>
          </div>
        </div>
      </div>
      {fund.coverImageCloudinaryId && (
        <div
          css={[
            { display: "none" },
            cssForMediaSize({
              min: MediaSize.XX_LARGE,
              css: css`
                margin: ${spacing.xxl};
                flex: 1;
                position: relative;
                display: inline-block;
                border-radius: 16px;
                overflow: hidden;
                background-image: url("${constructCloudinaryUrl({
                  cloudinaryId: fund.coverImageCloudinaryId,
                  dimensions: { height: 500 },
                })}");
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
              `,
            }),
          ]}
        />
      )}
    </HeaderCard>
  );
};
