import {
  Button,
  ButtonRole,
  ButtonSize,
  ButtonTargetKind,
} from "@components/Button";
import { NonprofitCard as NewNonprofitCard } from "@components/feed/NewNonprofitCard";
import { DonateButton } from "@components/layout/DonateButton";
import { css } from "@emotion/react";
import { UUID } from "io-ts-types/UUID";
import React from "react";

import { FeedNonprofitRecResponse } from "@every.org/common/src/codecs/entities";
import { FeedItemType } from "@every.org/common/src/entity/types";
import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";

import { useNonprofit } from "src/context/NonprofitsContext/hooks";
import { nonprofitOrUndefined } from "src/context/NonprofitsContext/selectors";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { horizontalStackCss } from "src/theme/spacing";
import { ClickAction } from "src/utility/analytics";
import { logger } from "src/utility/logger";

const NonprofitCardActions: React.FCC<{
  nonprofit: ContextNonprofit;
  numSupporters?: number;
}> = ({ nonprofit, numSupporters }) => {
  return (
    <div
      css={[
        horizontalStackCss.m,
        css`
          justify-content: flex-end;
        `,
      ]}
    >
      <Button
        data-tname={"CauseFeedNonprofitCard--fundraise"}
        size={ButtonSize.SMALL}
        role={ButtonRole.SECONDARY}
        onClick={{
          kind: ButtonTargetKind.LINK,
          to: getRoutePath({
            format: URLFormat.RELATIVE,
            name: ClientRouteName.FUNDRAISER_CREATE,
            tokens: { nonprofitSlug: nonprofit.primarySlug },
          }),
        }}
      >
        Fundraise
      </Button>
      <DonateButton
        data-tname="causeFeedDonateButton"
        data-action={ClickAction.DONATE}
        role={ButtonRole.PRIMARY}
        donationsEnabled={nonprofit.donationsEnabled}
        primarySlug={nonprofit.primarySlug}
      >
        Donate
      </DonateButton>
    </div>
  );
};

interface CauseFeedNonprofitCardProps {
  item: FeedNonprofitRecResponse;
  feedId?: UUID;
  /**
   * Turns the cards into links to the admin page instead of the nonprofit's
   * profile.
   */
  isAdminLink?: boolean;
}

/**
 * Card that displays a nonprofit based on a feed nonprofit response.
 */
export const CauseFeedNonprofitCard: React.FCC<CauseFeedNonprofitCardProps> = ({
  item,
  feedId,
  isAdminLink,
}) => {
  const nonprofit = nonprofitOrUndefined(
    useNonprofit({ id: item.nonprofitId })
  );

  if (!nonprofit) {
    logger.error({
      message: "unexpectedly loading for feed nonprofit card, bad local state",
    });
    return null;
  }

  const { supporterInfo, supporterCount } = nonprofit;
  const numSupporters = supporterInfo?.numSupporters || supporterCount;

  return (
    <NewNonprofitCard
      causeCategoryBackgroundColor={false}
      data-feed-feedid={feedId}
      data-feed-itemid={nonprofit.id}
      data-feed-itemtype={FeedItemType.NONPROFIT_RECOMMENDATION}
      nonprofit={nonprofit}
      customFooter={
        nonprofit.donationsEnabled &&
        !nonprofit.archived && (
          <NonprofitCardActions
            nonprofit={nonprofit}
            numSupporters={numSupporters}
          />
        )
      }
    />
  );
};
