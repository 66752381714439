import "src/utilities/wdyr";

// essential polyfills come first and are part of the main bundle
import "src/utilities/polyfills/object";
import "focus-visible";
import { AuthGateway } from "@components/AuthGateway";
import { css, Global } from "@emotion/react";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import App, { AppContext, AppProps } from "next/app";
import { NextParsedUrlQuery } from "next/dist/server/request-meta";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import { Fragment, useEffect } from "react";
import Modal from "react-modal";

import { IotsCodecError } from "@every.org/common/src/errors/IotsCodecError";
import {
  ClientRouteName,
  getClientRouteFromPath,
} from "@every.org/common/src/helpers/clientRoutes";
import { assertEnvPresent } from "@every.org/common/src/helpers/getEnv";

import { modalGlobalCss } from "src/components/Modal";
import { FacebookPixel } from "src/components/app/FacebookPixel";
import { NoScript } from "src/components/app/NoScript";
import { VendorScripts } from "src/components/app/VendorScripts";
import { WelcomeModal } from "src/components/layout/WelcomeModal";
import { ContextProviders } from "src/context/ContextProviders";
import { parseNextRoute } from "src/hooks/useEdoRouter";
import { EdoErrorPage } from "src/pages/EdoErrorPage";
import { globalCss } from "src/theme/GlobalStyle";
import {
  installOptionalPolyfills,
  installRequiredPolyfills,
} from "src/utilities/polyfills";
import { trackPageChange } from "src/utility/analytics";
import { logger, LogServices } from "src/utility/logger";
import { getServerSideTestingProps } from "src/utility/statsig";
import { getWindow } from "src/utility/window";

// These routes call trackPageChange within their component for greater
// granularity.
const TRACK_IN_COMPONENT = new Set([
  ClientRouteName.NONPROFIT_OR_CAUSE,
  ClientRouteName.USER,
  ClientRouteName.USER_JOINS,
  ClientRouteName.USER_LIKES,
  ClientRouteName.USER_LISTS,
]);

function logAndShowStartupError(error: Error) {
  logger.error({
    message: "A fatal error occurred loading the app.",
    error,
  });
  const body = getWindow()?.document.getElementsByTagName("body")[0];
  if (body) {
    body.innerHTML = /* html */ `
    <div style="height: 100vh; width: 100%; display: flex; align-items: center; justify-content: center; font-family: Arial; sans-serif; font-weight: normal;">
      <div style="max-width: 600px; text-align: center;">
        <h3 style="font-weight: normal;">
          Every.org is not working on this browser! Try reloading, using a newer browser, or feel
          free to <a
          href="https://support.every.org/hc/en-us/requests/new">contact us</a>
        </h3>
      </div>
    </div>
    `;
  }
}

const DefaultHead = () => (
  <Fragment>
    <VendorScripts />
    <Head>
      <meta charSet="utf-8" />
      {/* START Icons */}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#2bd7b0" />
      {/* END Icons */}
      <link rel="preconnect" href="https://res.cloudinary.com" />
      <link
        rel="preconnect"
        href={assertEnvPresent(
          process.env.NEXT_PUBLIC_API_ORIGIN,
          "NEXT_PUBLIC_API_ORIGIN"
        )}
      />

      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#ffffff" />
      {/* Open Graph and Facebook properties. Do not add any of the dynamically
    controlled tags like og:title, og:description and og:image specified by
    MetaTags in DefaultPageLayout.tsx otherwise they canot be properly
    controlled. */}
      <meta property="og:site_name" content="Every.org" />
      <meta
        property="fb:app_id"
        content={assertEnvPresent(
          process.env.NEXT_PUBLIC_FB_ID,
          "NEXT_PUBLIC_FB_ID"
        )}
      />
      <meta property="og:type" content="website" />
      {/* Twitter tags
    Ideally the alt text would also be dynamically controlled and relevant to the image. */}
      <meta
        name="twitter:image:alt"
        content="Every.org helps people support a wide variety of causes."
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@everydotorg" />
      <meta name="twitter:creator" content="@everydotorg" />
      {/* manifest.json provides metadata used when your web app is installed on
      a user's mobile device or desktop. See
      https://developers.google.com/web/fundamentals/web-app-manifest/ */}
      <link rel="manifest" href="/manifest.json" />
    </Head>

    {/* Stripe suggests including their library on every page
    to assist their fraud prevention algorithms. */}
    <Script async defer src="https://js.stripe.com/v3/" />
    <Script
      async
      defer
      id="plaid-js"
      src="https://cdn.plaid.com/link/v2/stable/link-initialize.js"
    />
    <FacebookPixel />
    <NoScript />
  </Fragment>
);
const fontCss = css`
  @font-face {
    font-family: "Basis Grotesque";
    src: url("/fonts/BasisGrotesqueWeb/BasisGrotesque-Bold-Pro.woff2")
        format("woff2"),
      url("/fonts/BasisGrotesqueWeb/BasisGrotesque-Bold-Pro.woff")
        format("woff"),
      url("/fonts/BasisGrotesqueWeb/BasisGrotesque-Bold-Pro.ttf")
        format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Basis Grotesque";
    src: url("/fonts/BasisGrotesqueWeb/BasisGrotesque-Medium-Pro.woff2")
        format("woff2"),
      url("/fonts/BasisGrotesqueWeb/BasisGrotesque-Medium-Pro.woff")
        format("woff"),
      url("/fonts/BasisGrotesqueWeb/BasisGrotesque-Medium-Pro.ttf")
        format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Basis Grotesque";
    src: url("/fonts/BasisGrotesqueWeb/BasisGrotesque-Regular-Pro.woff2")
        format("woff2"),
      url("/fonts/BasisGrotesqueWeb/BasisGrotesque-Regular-Pro.woff")
        format("woff"),
      url("/fonts/BasisGrotesqueWeb/BasisGrotesque-Regular-Pro.ttf")
        format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
`;
const MyApp = ({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Component,
  pageProps,
}: AppProps) => {
  useEffect(() => {
    Modal.setAppElement("#__next");
    installOptionalPolyfills();
    installRequiredPolyfills().catch((error) => logAndShowStartupError(error));
  }, []);

  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (
      nextRoute: string,
      query: NextParsedUrlQuery
    ) => {
      const parsedRoute = parseNextRoute(nextRoute, query);
      const clientRoute = getClientRouteFromPath(parsedRoute, nextRoute);
      if (
        clientRoute &&
        !TRACK_IN_COMPONENT.has(clientRoute as ClientRouteName)
      ) {
        trackPageChange(clientRoute);
      }
    };
    if (router.isReady) {
      handleRouteChange(router.pathname, router.query);
    }
  }, [router.pathname, router.query, router.isReady]);

  return (
    <SentryErrorBoundary
      beforeCapture={(scope, error) => {
        if (error?.name === "ChunkLoadError") {
          // happens frequenty when we deploy and old chunks are purged; we will
          // refresh in onError callback so this is jarring but not fatal
          scope.setLevel("info");
        } else {
          scope.setLevel("fatal");
        }
      }}
      onError={(error, componentStack) => {
        const logFn =
          error.name === "ChunkLoadError" ? logger.info : logger.fatal;
        if (error instanceof IotsCodecError) {
          logFn({
            message:
              "An uncaught io-ts validation error occurred in the React app.",
            data: { errorMessages: error.data.errorMessages },
            excludeLogServices: [LogServices.SENTRY], // already sent by error boundary
          });
        } else {
          logFn({
            message: "An uncaught error occurred in the React app.",
            error,
            excludeLogServices: [LogServices.SENTRY], // already sent by error boundary
          });
        }
        const window = getWindow();
        if (window && error.name === "ChunkLoadError") {
          window.location.reload();
        }
      }}
      fallback={({ error }) =>
        error.name !== "ChunkLoadError" ? <EdoErrorPage /> : <div />
      }
    >
      <DefaultHead />
      <Global styles={[globalCss, modalGlobalCss, fontCss]} />
      <ContextProviders initialData={pageProps.initialData}>
        <AuthGateway>
          <Component {...pageProps} />
          <WelcomeModal />
        </AuthGateway>
      </ContextProviders>
    </SentryErrorBoundary>
  );
};

// This function adds two props, abtestingId and statsigInitProps
// to the primary app's props. These properties ONLY get refreshed
// if getInitialProps runs on the server, and otherwise we try to
// get their values from window.__NEXT_DATA__ (I'm not sure what that is, and it
// seems like an internal API)
// Documentation on this pattern which NextJS does not seem to recommend anymore
// https://nextjs.org/docs/pages/building-your-application/routing/custom-app#getinitialprops-with-app
// Also mentioned in this comment:
// https://www.reddit.com/r/nextjs/comments/ohkyvg/comment/h4q636i/
// Documentation on the pattern of storing a stable ID in a cookie:
// https://www.statsig.com/faq/how-do-you-create-a-stable-id-for-server-side-experiments
MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  const { statsigInitProps, abTestingId } = await getServerSideTestingProps(
    appContext.ctx
  );

  return {
    ...appProps,
    pageProps: {
      ...appProps.pageProps,
      initialData: {
        ...(appProps.pageProps.initialData || {}),
        abTestingId,
        statsigInitProps,
      },
    },
  };
};

export default MyApp;
