import { FeedbackLink } from "@components/FeedbackLink";
import {
  FooterNavList,
  FooterNavListProps,
} from "@components/layout/FooterNavList";
import { PageSection } from "@components/layout/PageSection";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useMemo } from "react";

import { colorPalette } from "@every.org/common/src/display/palette";
import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";

import {
  Button,
  ButtonSize,
  ButtonRole,
  ButtonTargetKind,
} from "src/components/Button";
import { Icon, IconSize, IconDisplay } from "src/components/Icon";
import { Link } from "src/components/Link";
import { useEdoTheme } from "src/context/ThemeContext";
import { colorCssVars, lightBgThemeCss, darkBgThemeCss } from "src/theme/color";
import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";
import { spacing, horizontalStackCss } from "src/theme/spacing";
import { TextSize, textSizeCss } from "src/theme/text";

const StyledFooter = styled.footer`
  > * {
    padding-bottom: ${spacing.oldM};
  }
`;

const VerticalSeparator = styled.div<{ isLight?: boolean }>`
  border-left: 1px solid
    ${({ isLight }) =>
      isLight ? "#cccccc" : `var(${colorCssVars.text.secondary})`};
  height: 20px;
  ${cssForMediaSize({
    max: MediaSize.MEDIUM,
    css: css`
      display: none;
    `,
  })}
`;
const BottomFooter = styled.div`
  ${horizontalStackCss.l};
  align-items: center;
  flex-wrap: wrap;
  & > * {
    padding-top: ${spacing.oldM};
  }
`;

const TextSecondary = styled.p`
  ${textSizeCss[TextSize.xxs]};
`;

const FooterButtons = styled.div`
  ${horizontalStackCss.xs};
  align-self: center;
  ${cssForMediaSize({
    max: MediaSize.MEDIUM,
    css: css`
      order: -1;
      padding-right: ${spacing.s};
    `,
  })}
`;

const LINKEDIN_ONCLICK = {
  kind: ButtonTargetKind.LINK,
  to: "https://www.linkedin.com/company/everydotorg/",
} as const;
const INSTA_ONCLICK = {
  kind: ButtonTargetKind.LINK,
  to: "https://www.instagram.com/everydotorg/",
} as const;
const TWITTER_ONCLICK = {
  kind: ButtonTargetKind.LINK,
  to: "https://x.com/everydotorg",
} as const;
const FB_ONCLICK = {
  kind: ButtonTargetKind.LINK,
  to: "https://facebook.com/everydotorg",
} as const;
const GITHUB_ONCLICK = {
  kind: ButtonTargetKind.LINK,
  to: "https://github.com/everydotorg",
} as const;

const sectionContentCss = (isLight?: boolean) => css`
  padding-right: ${spacing.l};
  padding-left: ${spacing.l};
  ${textSizeCss[TextSize.xs]};
  ${horizontalStackCss.none};
  justify-content: space-between;
  flex-wrap: wrap;
  a {
    color: ${isLight ? colorPalette.grayMedium : "#cccccc"};
    text-decoration: none;

    &:hover {
      color: ${isLight ? colorPalette.blackWarm : "white"};
    }
  }

  p {
    color: ${isLight ? colorPalette.grayMedium : "#cccccc"};
  }
`;

export const Footer: React.FCC<{ className?: string }> = React.memo(
  function FooterImpl({ className }) {
    const { isLight } = useEdoTheme();
    const footerLinks = useMemo<FooterNavListProps["entries"]>(
      () => [
        {
          elem: (
            <Link
              title="Link to About page"
              to={getRoutePath({
                format: URLFormat.ABSOLUTE,
                name: ClientRouteName.ABOUT,
              })}
              data-tname="Footer--About"
            >
              About
            </Link>
          ),
          key: "about",
        },
        {
          elem: (
            <Link
              title="Link to Support"
              to={getRoutePath({
                format: URLFormat.ABSOLUTE,
                name: ClientRouteName.SUPPORT,
              })}
              data-tname="Footer--Support"
            >
              Support
            </Link>
          ),
          key: "support",
        },
        {
          elem: (
            <Link
              title="Link to Nonprofits page"
              to={getRoutePath({
                format: URLFormat.ABSOLUTE,
                name: ClientRouteName.NONPROFITS_LANDING,
              })}
              data-tname="Footer--Nonprofits"
            >
              Nonprofits
            </Link>
          ),
          key: "Nonprofits",
        },
        {
          elem: (
            <Link
              title="Link to API page"
              to={getRoutePath({
                format: URLFormat.RELATIVE,
                name: ClientRouteName.API_LANDING,
              })}
              data-tname="Footer--API"
            >
              API
            </Link>
          ),
          key: "API",
        },
        {
          elem: (
            <FeedbackLink
              title="Open feedback widget"
              data-tname="Footer--Feedback"
            >
              Feedback
            </FeedbackLink>
          ),
          key: "Feedback",
        },
        {
          elem: (
            <Link
              title="Link to Blog page"
              to={getRoutePath({
                format: URLFormat.ABSOLUTE,
                name: ClientRouteName.BLOG,
              })}
              data-tname="Footer--Blog"
              forceExternal
            >
              Blog
            </Link>
          ),
          key: "Blog",
        },
        {
          elem: (
            <Link
              title="Link to Press page"
              to={getRoutePath({
                format: URLFormat.ABSOLUTE,
                name: ClientRouteName.PRESS,
              })}
              data-tname="Footer--Press"
              forceExternal
            >
              Press
            </Link>
          ),
          key: "Press",
        },
        {
          elem: (
            <Link
              title="Link to Terms of Service"
              to={getRoutePath({
                format: URLFormat.RELATIVE,
                name: ClientRouteName.TERMS_OF_SERVICE,
              })}
              data-tname="Footer--Terms"
            >
              Terms
            </Link>
          ),
          key: "terms",
        },
        {
          elem: (
            <Link
              title="Link to Privacy Policy"
              to={getRoutePath({
                format: URLFormat.RELATIVE,
                name: ClientRouteName.PRIVACY_POLICY,
              })}
              data-tname="Footer--Privacy"
            >
              Privacy
            </Link>
          ),
          key: "privacy",
        },
      ],
      []
    );
    return (
      <StyledFooter
        className={className}
        role="contentinfo"
        css={css`
          ${isLight ? lightBgThemeCss : darkBgThemeCss}
        `}
      >
        <PageSection
          role="navigation"
          aria-label="Footer navigation"
          withElement="nav"
          contentCss={sectionContentCss(isLight)}
        >
          <div
            css={css`
              padding-top: ${spacing.oldM};
            `}
          >
            <FooterNavList entries={footerLinks} />
          </div>
          <BottomFooter>
            <TextSecondary>
              &#169; {new Date().getFullYear().toString()} Every.org 501(c)(3)
              EIN 61-1913297
            </TextSecondary>
            <div>
              <VerticalSeparator isLight={isLight} />
            </div>
            <FooterButtons>
              <Button
                title="Link to Facebook"
                data-tname="footerFacebookLink"
                size={ButtonSize.SMALL}
                role={ButtonRole.TEXT_ONLY}
                icon={
                  <Icon
                    iconImport={() =>
                      import("@components/Icon/icons/FacebookIcon")
                    }
                    size={IconSize.X_SMALL}
                    display={IconDisplay.CURRENT_COLOR}
                  />
                }
                onClick={FB_ONCLICK}
              />
              <Button
                title="Link to Instagram"
                data-tname="footerInstagramLink"
                size={ButtonSize.SMALL}
                role={ButtonRole.TEXT_ONLY}
                icon={
                  <Icon
                    iconImport={() =>
                      import("@components/Icon/icons/InstagramLogoIcon")
                    }
                    size={IconSize.SMALL}
                    display={IconDisplay.CURRENT_COLOR}
                  />
                }
                onClick={INSTA_ONCLICK}
              />
              <Button
                title="Link to Linkedin"
                data-tname="footerLinkedInLink"
                size={ButtonSize.SMALL}
                role={ButtonRole.TEXT_ONLY}
                icon={
                  <Icon
                    iconImport={() =>
                      import("@components/Icon/icons/LinkedinLogoIcon")
                    }
                    size={IconSize.SMALL}
                    display={IconDisplay.CURRENT_COLOR}
                  />
                }
                onClick={LINKEDIN_ONCLICK}
              />
              <Button
                title="Link to Twitter"
                data-tname="footerTwitterLink"
                size={ButtonSize.SMALL}
                role={ButtonRole.TEXT_ONLY}
                icon={
                  <Icon
                    iconImport={() =>
                      import("@components/Icon/icons/XLogoFooter")
                    }
                    size={IconSize.SMALL}
                    display={IconDisplay.CURRENT_COLOR}
                  />
                }
                onClick={TWITTER_ONCLICK}
              />
              <Button
                title="Link to Github"
                data-tname="footerGitHubInLink"
                size={ButtonSize.SMALL}
                role={ButtonRole.TEXT_ONLY}
                icon={
                  <Icon
                    iconImport={() =>
                      import("@components/Icon/icons/GitHubIcon")
                    }
                    size={IconSize.X_SMALL}
                    display={IconDisplay.CURRENT_COLOR}
                  />
                }
                onClick={GITHUB_ONCLICK}
              />
            </FooterButtons>
          </BottomFooter>
        </PageSection>
      </StyledFooter>
    );
  }
);
