import { AvatarSize } from "@components/Avatar";
import { NonprofitAvatar } from "@components/Avatar/NonprofitAvatar";
import { Card } from "@components/Card";
import { NonprofitIdLink, NonprofitLink } from "@components/NonprofitLink";
import { UserIdLink } from "@components/UserLink";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useContext } from "react";

import {
  FundCreatingEntityType,
  NonprofitType,
} from "@every.org/common/src/entity/types";
import { notUndefinedOrNull } from "@every.org/common/src/helpers/objectUtilities";

import { NonprofitsContext } from "src/context/NonprofitsContext";
import {
  getNonprofit,
  nonprofitOrUndefined,
} from "src/context/NonprofitsContext/selectors";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { LinkAppearance } from "src/styles/link";
import { truncatedTextCss } from "src/styles/truncatedTextCss";
import { colorCssVars } from "src/theme/color";
import { horizontalStackCss, spacing } from "src/theme/spacing";
import { textSizeCss, TextSize } from "src/theme/text";

interface NonprofitSupportingFundsProps {
  nonprofitIds: ContextNonprofit["id"][];
}

const Title = styled.h2`
  ${textSizeCss[TextSize.m]};
  margin-bottom: ${spacing.xs};
`;

const HEADING_CSS = [truncatedTextCss({ numLines: 2 })];

export function NonprofitSupportingFunds({
  nonprofitIds,
}: NonprofitSupportingFundsProps) {
  const nonprofitsState = useContext(NonprofitsContext);
  const endorsers = nonprofitIds
    .map((nonprofitId) => {
      const nonprofit = nonprofitOrUndefined(
        getNonprofit(nonprofitsState, {
          id: nonprofitId,
        })
      );
      if (
        !nonprofit ||
        nonprofit.type !== NonprofitType.FUND ||
        !nonprofit.donationsEnabled
      ) {
        return null;
      }
      return nonprofit;
    })
    .filter(notUndefinedOrNull);
  if (endorsers.length === 0) {
    return null;
  }
  return (
    <Card>
      <Title>Featured in</Title>
      <div>
        <ul
          css={css`
            > :not(:last-child) {
              padding-bottom: ${spacing.l};
              border-bottom: 1px solid var(${colorCssVars.dividerSoft});
            }
          `}
        >
          {endorsers.map((nonprofit) => {
            return (
              <li
                css={css`
                  padding-top: ${spacing.m};
                `}
                key={`supporting-fund-${nonprofit.id}`}
              >
                <article css={horizontalStackCss.m}>
                  <section>
                    <NonprofitAvatar
                      nonprofit={nonprofit}
                      size={AvatarSize.MEDIUM}
                    />
                  </section>
                  <section
                    css={css`
                      margin-top: auto;
                      margin-bottom: auto;
                      max-width: 80%;
                    `}
                  >
                    <h3 css={HEADING_CSS}>
                      <NonprofitLink
                        data-tname={"supporting-fund-name"}
                        nonprofit={nonprofit}
                        appearance={LinkAppearance.HYPERLINK_UNCOLORED}
                      />
                    </h3>
                    {nonprofit.fundCreatingEntityId && (
                      <p
                        css={css`
                          display: flex;
                          color: var(${colorCssVars.text.secondary});
                        `}
                      >
                        By&nbsp;
                        {nonprofit.fundCreatingEntityType ===
                          FundCreatingEntityType.NONPROFIT && (
                          <NonprofitIdLink
                            nonprofitId={nonprofit.fundCreatingEntityId}
                            data-tname={"supporting-fund-name"}
                            appearance={LinkAppearance.HYPERLINK_UNCOLORED}
                          />
                        )}
                        {nonprofit.fundCreatingEntityType ===
                          FundCreatingEntityType.USER && (
                          <UserIdLink
                            userId={nonprofit.fundCreatingEntityId}
                            appearance={LinkAppearance.HYPERLINK_UNCOLORED}
                          />
                        )}
                      </p>
                    )}
                  </section>
                </article>
              </li>
            );
          })}
        </ul>
      </div>
    </Card>
  );
}
