import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import { MarkdownText } from "@components/MarkdownText";
import { NewlineAwareText } from "@components/NewlineAwareText";
import { css } from "@emotion/react";
import React, { useState } from "react";

import { spacing } from "@every.org/common/src/display/spacing";

import { truncatedTextCss } from "src/styles/truncatedTextCss";
import { MediaQueryConstraint } from "src/theme/mediaQueries";

enum TruncatedStatus {
  INITIAL = "INITIAL",
  TRUNCATED = "TRUNCATED",
  EXPANDED = "EXPANDED",
}

export const TextWithReadMore: React.FCC<{
  text: string;
  numLines: number;
  buttonText?: string;
  mediaQuery?: {
    numLines: number;
  } & MediaQueryConstraint;
  markdown?: boolean;
}> = ({ text, numLines, buttonText, mediaQuery, markdown = false }) => {
  const [truncatedStatus, setTruncatedStatus] = useState<TruncatedStatus>(
    TruncatedStatus.INITIAL
  );

  const readMoreTextRefCallback = (node: HTMLDivElement | null) => {
    if (node !== null) {
      const { scrollHeight, clientHeight } = node;
      if (scrollHeight && clientHeight) {
        const lineHeight = parseFloat(getComputedStyle(node).lineHeight || "0");

        const hiddenLines =
          lineHeight > 0
            ? Math.floor((scrollHeight - clientHeight) / lineHeight)
            : 0;
        if (hiddenLines >= 3) {
          setTruncatedStatus(TruncatedStatus.TRUNCATED);
        } else {
          setTruncatedStatus(TruncatedStatus.EXPANDED);
        }
      }
    }
  };

  return (
    <React.Fragment>
      {markdown ? (
        <div
          ref={readMoreTextRefCallback}
          css={[
            truncatedStatus !== TruncatedStatus.EXPANDED &&
              truncatedTextCss({ numLines, mediaQuery }),
            css`
              -webkit-box-orient: unset;
            `,
          ]}
        >
          <MarkdownText content={text} />
        </div>
      ) : (
        <NewlineAwareText
          ref={readMoreTextRefCallback}
          content={text}
          css={[
            truncatedStatus !== TruncatedStatus.EXPANDED &&
              truncatedTextCss({ numLines, mediaQuery }),
            css`
              -webkit-box-orient: unset;
            `,
          ]}
        />
      )}
      {truncatedStatus === TruncatedStatus.TRUNCATED && (
        <div css={{ paddingTop: spacing.xxs }}>
          <Button
            data-tname="descriptionLong-SeeMore"
            role={ButtonRole.TEXT_ONLY}
            onClick={{
              kind: ButtonTargetKind.FUNCTION,
              action: () => setTruncatedStatus(TruncatedStatus.EXPANDED),
            }}
          >
            {buttonText ? buttonText : "Read more"}
          </Button>
        </div>
      )}
    </React.Fragment>
  );
};
