import { AvatarSize, avatarSizeToPx } from "@components/Avatar";
import { NonprofitAvatar } from "@components/Avatar/NonprofitAvatar";
import { ButtonRole, ButtonSize } from "@components/Button";
import { Card } from "@components/Card";
import {
  HeaderCardMetadataModalButton,
  LayoutDirection,
} from "@components/Profile/HeaderCardInfo";
import { ResizingSpan } from "@components/ResizingSpan";
import {
  ShareButton,
  getSupportNonprofitShareData,
} from "@components/ShareButton";
import { StartFundraiserButton } from "@components/StartFundraiserButton";
import { NonprofitSupporters } from "@components/SupportersList/NonprofitSupporters";
import { useShowFundraiserLink } from "@components/donate/DonateV3/useGridCardsDisplayController";
import { LikeButtonWithCounter } from "@components/feed/LikeButton";
import { DonateButton } from "@components/layout/DonateButton";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import { NonEmptyMatchingCampaignResponse } from "@every.org/common/src/codecs/entities";
import { TextSize } from "@every.org/common/src/display/text";
import { LikeableType } from "@every.org/common/src/entity/types";
import {
  getRoutePath,
  URLFormat,
  ClientRouteName,
} from "@every.org/common/src/helpers/clientRoutes";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { useNonprofitAnnouncement } from "src/hooks/useNonprofitAnnouncement";
import { smallScreenAnnouncementCss } from "src/pages/Nonprofit/NonprofitPageV3/styles";
import { colorCssVars } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import {
  horizontalStackCss,
  spacing,
  verticalStackCss,
} from "src/theme/spacing";
import { FontWeight, textSizeCss } from "src/theme/text";
import { nonprofitShareImageCloudinaryParams } from "src/utility/cloudinary/nonprofit";
import {
  DEFAULT_SHARE_IMAGE_CLOUDINARY_PARAMS,
  INSTAGRAM_POST_DIMENSIONS,
} from "src/utility/opengraph";

const StyledCard = styled(Card)`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${spacing.l};

  ${cssForMediaSize({
    min: MediaSize.MEDIUM_LARGE,
    css: css`
      grid-column-gap: ${spacing.xxl};
      grid-template-columns: 1fr auto;
    `,
  })}
`;

const Divider = styled.div`
  height: 24px;
  border-left: 1px var(${colorCssVars.dividerSoft}) solid;
  display: none;
  ${cssForMediaSize({
    min: MediaSize.LARGE,
    css: css`
      display: block;
    `,
  })}
`;

const LeftSideContainer = styled.div<{ description: boolean }>`
  display: grid;
  grid-column-gap: ${spacing.m};
  grid-row-gap: ${spacing.xs};
  grid-template-columns: auto 1fr;
  grid-template-areas: "avatar name";

  ${({ description }) =>
    description &&
    css`
      grid-template-areas:
        "avatar name"
        "description description";
    `}

  ${({ description }) =>
    description &&
    cssForMediaSize({
      min: MediaSize.MEDIUM_LARGE,
      css: css`
        grid-template-areas:
          "avatar name"
          "avatar description";
      `,
    })}
`;

const avtarCss = css`
  grid-area: avatar;
  align-self: center;
`;

const nonprofitNameCss = css`
  grid-area: name;
  align-self: center;
`;

const nonprofitNameWithDescriptionCss = css`
  grid-area: name;
  align-self: center;
  ${cssForMediaSize({
    min: MediaSize.MEDIUM_LARGE,
    css: css`
      align-self: end;
    `,
  })}
`;

const nonprofitDescriptionCss = css`
  grid-area: description;
  /* align-self: start; */
`;

const RightSideContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: ${spacing.l};

  ${cssForMediaSize({
    min: MediaSize.MEDIUM_LARGE,
    css: css`
      gap: ${spacing.s};
    `,
  })}

  ${cssForMediaSize({
    min: MediaSize.XX_LARGE,
    css: css`
      align-items: center;
      flex-direction: row;
      justify-content: flex-end;
    `,
  })}
`;

interface NonprofitPageV3HeaderCardProps {
  nonprofit: ContextNonprofit;
  nonprofitMatchingCampaign?: NonEmptyMatchingCampaignResponse;
  className?: string;
}

export const NonprofitPageV3HeaderCard: React.FCC<
  NonprofitPageV3HeaderCardProps
> = ({ nonprofit, nonprofitMatchingCampaign, className }) => {
  const { id, donationCount, supporterCount, metadata } = nonprofit;

  const hideDonationCount = metadata?.hideDonationCount;

  const showFundraiserLink = useShowFundraiserLink(nonprofit);
  const announcement = useNonprofitAnnouncement(
    nonprofit,
    nonprofitMatchingCampaign
  );

  return (
    <StyledCard>
      <LeftSideContainer description={!!nonprofit.description}>
        <span css={avtarCss}>
          <NonprofitAvatar
            nonprofit={nonprofit}
            size={AvatarSize.MEDIUM}
            customCSS={cssForMediaSize({
              min: MediaSize.MEDIUM_LARGE,
              css: css`
                width: ${avatarSizeToPx[AvatarSize.X_LARGE]}px;
                height: ${avatarSizeToPx[AvatarSize.X_LARGE]}px;
              `,
            })}
          />
        </span>
        <h1
          css={[
            textSizeCss.s,
            nonprofit.description
              ? nonprofitNameWithDescriptionCss
              : nonprofitNameCss,
          ]}
        >
          <ResizingSpan
            css={{ width: "100%" }}
            maxTextSize={TextSize.xl}
            minTextSize={TextSize.m}
            compressor={
              typeof nonprofit.name === "string" && nonprofit.name.length > 24
                ? 2
                : 1
            }
          >
            {nonprofit.name}
          </ResizingSpan>
        </h1>
        {nonprofit.description && (
          <p css={nonprofitDescriptionCss}>{nonprofit.description}</p>
        )}
      </LeftSideContainer>
      <RightSideContainer>
        <div
          css={[
            horizontalStackCss.s,
            { alignItems: "center", justifyContent: "space-between" },
          ]}
        >
          {donationCount && supporterCount && !hideDonationCount && (
            <HeaderCardMetadataModalButton
              headerCardMetadataProps={{
                label: donationCount > 1 ? "donations" : "donation",
                data: `${donationCount}`,
                microdataInteractionType: "https://schema.org/DonateAction",
                layoutDirection: LayoutDirection.HORIZONTAL,
                labelFirst: false,
                entryCssOverride: css`
                  display: flex;
                  flex-shrink: 0;
                  color: var(${colorCssVars.text.secondary});
                  font-weight: ${FontWeight.MEDIUM};
                  :hover {
                    color: var(${colorCssVars.text.body});
                  }
                `,
              }}
              modalButtonProps={{
                buttonProps: {
                  "data-tname": "NonprofitProfile-Following",
                  role: ButtonRole.UNSTYLED,
                },
                modalHeader: "Donors",
                modalContentLabel: "Donors Modal",
                modalContent: function ModalContent(setIsModalOpen) {
                  return (
                    <NonprofitSupporters
                      nonprofitId={id}
                      supporterCount={supporterCount}
                      onClick={() => {
                        setIsModalOpen(false);
                      }}
                    />
                  );
                },
              }}
            />
          )}
          <Divider />
          <div
            css={[
              horizontalStackCss.none,
              { alignItems: "center", marginLeft: "auto" },
            ]}
          >
            <ShareButton
              aria-label="Share nonprofit"
              data-tname="nonprofitShareButton"
              role={ButtonRole.TEXT_ONLY}
              hideText
              shareData={(medium) =>
                getSupportNonprofitShareData({
                  medium,
                  nonprofit,
                  body: nonprofit?.description || undefined,
                  url: getRoutePath({
                    name: ClientRouteName.NONPROFIT_OR_CAUSE,
                    tokens: { nonprofitSlug: nonprofit.primarySlug },
                    format: URLFormat.ABSOLUTE,
                  }),
                })
              }
              instagramImageCloudinaryParams={
                nonprofitShareImageCloudinaryParams({
                  logoCloudinaryId: nonprofit.logoCloudinaryId,
                  coverImageCloudinaryId: nonprofit.coverImageCloudinaryId,
                  imageHeight: INSTAGRAM_POST_DIMENSIONS.height,
                }) || DEFAULT_SHARE_IMAGE_CLOUDINARY_PARAMS
              }
              nonprofitSlug={nonprofit.primarySlug}
            />
            <LikeButtonWithCounter
              title="Like nonprofit"
              data-tname="likeButton"
              role={ButtonRole.TEXT_ONLY}
              size={ButtonSize.SMALL}
              type={LikeableType.NONPROFIT}
              id={nonprofit.id}
              likeCount={nonprofit.likesInfo?.count || null}
              loggedInUserLikes={
                nonprofit.likesInfo?.hasLoggedInUserLiked || null
              }
            />
          </div>
        </div>
        <div css={[verticalStackCss.s, { flexShrink: 0 }]}>
          {announcement && (
            <strong css={[smallScreenAnnouncementCss, textSizeCss.l]}>
              {announcement}
            </strong>
          )}
          <div css={[verticalStackCss.xs, { flexShrink: 0 }]}>
            {!metadata?.hideDonateButtons ? (
              <DonateButton
                data-tname="startDonateButton"
                donationsEnabled={nonprofit.donationsEnabled}
                primarySlug={nonprofit.primarySlug}
                size={ButtonSize.MEDIUM}
                toNonprofitPage
              />
            ) : null}
            {showFundraiserLink && (
              <StartFundraiserButton
                slug={nonprofit.primarySlug}
                donationsEnabled={nonprofit.donationsEnabled}
              />
            )}
          </div>
        </div>
      </RightSideContainer>
    </StyledCard>
  );
};
