import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import { Loading } from "@components/LoadingIndicator";
import { NonprofitHeaderCard } from "@components/Profile/NonprofitHeaderCard";
import { useJoinDonationParams } from "@components/donate/DonateRouteModal";
import { DonateModalAction } from "@components/donate/DonateV3/types";
import { NonprofitCard } from "@components/feed/NewNonprofitCard";
import { DefaultPageLayout } from "@components/layout/DefaultPageLayout";
import { ImageHeaderPageLayout } from "@components/layout/ImageHeaderPageLayout";
import { MasonryList } from "@components/layout/MasonryList";
import { PageSection } from "@components/layout/PageSection";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";

import { SHARED_PALETTE } from "@every.org/common/src/display/palette";
import {
  getRoutePath,
  ClientRouteName,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";
import { constructCloudinaryUrl } from "@every.org/common/src/helpers/cloudinary";
import { removeUndefinedOrNullValues } from "@every.org/common/src/helpers/objectUtilities";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import { useOpenDonatePage } from "src/hooks/useOpenDonatePage";
import { DirectoryFilter } from "src/pages/Directory/DirectoryFilter";
import { DirectoryFilterSearchInput } from "src/pages/Directory/DirectoryFilterSearchInput";
import {
  DirectoryContext,
  DirectoryPendingContext,
  DirectoryProjectsContext,
} from "src/pages/Directory/context";
import {
  nonprofitHeaderCardCss,
  pageGridCss,
  smallScreenSearhInputBoxCss,
  smallScreenSearhInputCss,
} from "src/pages/Directory/styles";
import DonateV3Page from "src/pages/DonateV3";
import { NoResults } from "src/pages/SearchResults";
import { spacing, verticalStackCss } from "src/theme/spacing";
import { ClickAction } from "src/utility/analytics";
import { nonprofitShareImageCloudinaryParams } from "src/utility/cloudinary/nonprofit";
import { OPENGRAPH_DIMENSIONS } from "src/utility/opengraph";
import { NONPROFIT_PAGE_HEADER_OVERLAP_HEIGHT } from "src/utility/pageMetadata";

const DirectoryPageLayout: React.FCC<{ nonprofit: ContextNonprofit }> = ({
  children,
  nonprofit,
}) => {
  const { query: routerQuery } = useRouter();

  const noImageHeader = routerQuery.viewport === "mobile";

  const opengraphImageCloudinaryParams = nonprofitShareImageCloudinaryParams({
    logoCloudinaryId: nonprofit.logoCloudinaryId,
    coverImageCloudinaryId: nonprofit.coverImageCloudinaryId,
    imageHeight: OPENGRAPH_DIMENSIONS.height,
  });

  const metas = removeUndefinedOrNullValues({
    "og:description": nonprofit.description,
    "og:image":
      opengraphImageCloudinaryParams &&
      constructCloudinaryUrl({
        ...opengraphImageCloudinaryParams,
        dimensions: OPENGRAPH_DIMENSIONS,
      }),
  });

  const canonical = getRoutePath({
    name: ClientRouteName.NONPROFIT_OR_CAUSE,
    tokens: { nonprofitSlug: nonprofit.primarySlug },
    format: URLFormat.ABSOLUTE,
  });

  if (noImageHeader) {
    return (
      <DefaultPageLayout
        pageTitle={nonprofit.name}
        metas={metas}
        canonical={canonical}
        pageContentCss={[verticalStackCss.xs, { paddingTop: spacing.xs }]}
        hideSearchbar
      >
        <meta itemProp="url" content={canonical} />
        {children}
      </DefaultPageLayout>
    );
  }

  return (
    <ImageHeaderPageLayout
      pageTitle={nonprofit.name}
      metas={metas}
      imgCloudinaryId={nonprofit.coverImageCloudinaryId}
      headerOverlapHeight={NONPROFIT_PAGE_HEADER_OVERLAP_HEIGHT}
      fallbackBackgroundColor={SHARED_PALETTE.nonprofitProfileHeaderBackground}
      canonical={canonical}
      pageContentCss={verticalStackCss.l}
      priority
      hideSearchbar
    >
      <meta itemProp="url" content={canonical} />
      {children}
    </ImageHeaderPageLayout>
  );
};

interface DirectoryPageProps {
  nonprofit: ContextNonprofit;
}

function DirectoryPage({ nonprofit }: DirectoryPageProps) {
  const { pending } = useContext(DirectoryPendingContext);
  const { filteredProjects, tagCounts, onViewMore, hasMore } = useContext(
    DirectoryProjectsContext
  );

  const { query: routerQuery } = useRouter();

  const numColumns =
    routerQuery.viewport === "mobile" || routerQuery.viewport === "tablet"
      ? 1
      : { default: 2, 1100: 1 };

  // Support donate link for directories with donate button
  const { push } = useEdoRouter();
  const [isSSR, setIsSSR] = useState(true);
  useEffect(() => {
    setIsSSR(false);
  }, []);
  const openDonatePage = useOpenDonatePage();
  const { donationToJoinId, userToJoinId } = useJoinDonationParams();
  const navigateToNonprofitPage = () => {
    push(
      getRoutePath({
        format: URLFormat.RELATIVE,
        name: ClientRouteName.NONPROFIT_OR_CAUSE,
        tokens: { nonprofitSlug: nonprofit.primarySlug },
      })
    );
  };
  if (
    openDonatePage &&
    !nonprofit.metadata?.hideDonateButtons &&
    !isSSR &&
    nonprofit.donationsEnabled &&
    !nonprofit.archived
  ) {
    return (
      <DonateV3Page
        donateAction={DonateModalAction.DONATE}
        nonprofit={nonprofit}
        isOpen
        onRequestClose={navigateToNonprofitPage}
        donationToJoinId={donationToJoinId || undefined}
        userToJoinId={userToJoinId || undefined}
      />
    );
  }

  return (
    <DirectoryPageLayout nonprofit={nonprofit}>
      <PageSection>
        <NonprofitHeaderCard
          nonprofit={nonprofit}
          css={nonprofitHeaderCardCss}
        />
      </PageSection>
      <PageSection contentCss={pageGridCss}>
        <DirectoryFilter counts={tagCounts} />
        <div css={verticalStackCss.m}>
          <DirectoryFilterSearchInput
            css={smallScreenSearhInputCss}
            inputBoxCss={smallScreenSearhInputBoxCss}
          />
          {pending ? (
            <Loading />
          ) : filteredProjects.length ? (
            <MasonryList
              numColumns={numColumns}
              items={filteredProjects.map((project) => ({
                key: `projects-${project.id}`,
                elem: <NonprofitCard nonprofit={project} />,
              }))}
            />
          ) : (
            <NoResults />
          )}
          {!pending && hasMore && (
            <Button
              data-tname={"ViewMoreProjects--Button"}
              data-action={ClickAction.VIEW_MORE}
              role={ButtonRole.TEXT_ONLY}
              onClick={{
                kind: ButtonTargetKind.FUNCTION,
                action: onViewMore,
              }}
            >
              View more
            </Button>
          )}
        </div>
      </PageSection>
    </DirectoryPageLayout>
  );
}

const DirectoryPageWithContext = (props: DirectoryPageProps) => {
  return (
    <DirectoryContext {...props}>
      <DirectoryPage {...props} />
    </DirectoryContext>
  );
};

export default DirectoryPageWithContext;
