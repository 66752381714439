import {
  ButtonSize,
  Button,
  ButtonRole,
  ButtonTargetKind,
} from "@components/Button";
import {
  ShareButton,
  getSupportNonprofitShareData,
} from "@components/ShareButton";
import { SupportedBy } from "@components/SupportedBy";
import {
  PrimaryActionsContainer,
  FeedCardActionsContainer,
  ShareAndLikeContainer,
} from "@components/feed/DonationCardActions";
import { LikeButtonWithCounter } from "@components/feed/LikeButton";
import { NonprofitCard as NewNonprofitCard } from "@components/feed/NewNonprofitCard";
import { SuggestedNonprofitCardHeader } from "@components/feed/SuggestedNonprofitCardHeader";
import { DonateButton } from "@components/layout/DonateButton";
import { UUID } from "io-ts-types/UUID";

import { FeedItemType, LikeableType } from "@every.org/common/src/entity/types";
import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { spacing } from "src/theme/spacing";
import { ClickAction } from "src/utility/analytics";
import { nonprofitShareImageCloudinaryParams } from "src/utility/cloudinary/nonprofit";
import {
  DEFAULT_SHARE_IMAGE_CLOUDINARY_PARAMS,
  INSTAGRAM_POST_DIMENSIONS,
} from "src/utility/opengraph";

/**
 * Props for SuggestedNonprofitCard
 */
interface SuggestedNonprofitCardProps {
  /**
   * Nonprofit to render suggestion for
   */
  nonprofit: ContextNonprofit;
  /**
   * Feed id for tracking purposes
   */
  feedId?: UUID;
  /**
   * Hide the header
   */
  hideHeader?: boolean;
  /**
   * Within the header, what text to show.
   */
  headerText?: string;
  /**
   * Within the header, hide
   * the cause text (eg Environment)
   */
  hideCauseText?: boolean;
  /**
   * Hide the primary action
   */
  hidePrimaryAction?: boolean;
  topPick?: boolean;
  donateCrypto?: boolean;
  className?: string;
}
/**
 * Given a nonprofit, renders a nonprofit suggestion card
 */
export const SuggestedNonprofitCard: React.FCC<SuggestedNonprofitCardProps> = ({
  nonprofit,
  feedId,
  hidePrimaryAction,
  hideCauseText,
  headerText = "Found in",
}) => {
  const { primarySlug, supporterInfo } = nonprofit;
  const tag = nonprofit.nonprofitTags?.length
    ? nonprofit.nonprofitTags[0]
    : undefined;

  const shareButton = (
    <ShareButton
      isFeedCardShare
      aria-label="Share suggested nonprofit"
      data-tname="shareSuggestedNonprofitButton"
      data-action={ClickAction.SHARE}
      role={ButtonRole.TEXT_ONLY}
      size={ButtonSize.SMALL}
      hideText
      buttonCss={{ padding: spacing.xxs }}
      shareData={(medium) =>
        getSupportNonprofitShareData({
          medium,
          nonprofit,
          body: nonprofit?.description || undefined,
          url: getRoutePath({
            name: ClientRouteName.NONPROFIT_OR_CAUSE,
            tokens: { nonprofitSlug: nonprofit.primarySlug },
            format: URLFormat.ABSOLUTE,
          }),
        })
      }
      instagramImageCloudinaryParams={
        nonprofitShareImageCloudinaryParams({
          logoCloudinaryId: nonprofit.logoCloudinaryId,
          coverImageCloudinaryId: nonprofit.coverImageCloudinaryId,
          imageHeight: INSTAGRAM_POST_DIMENSIONS.height,
        }) || DEFAULT_SHARE_IMAGE_CLOUDINARY_PARAMS
      }
    />
  );

  const footer = (
    <FeedCardActionsContainer>
      <ShareAndLikeContainer>
        {shareButton}
        <LikeButtonWithCounter
          aria-label="Like this nonprofit"
          data-tname="likeButton"
          data-action={ClickAction.LIKE}
          role={ButtonRole.TEXT_ONLY}
          size={ButtonSize.SMALL}
          type={LikeableType.NONPROFIT}
          id={nonprofit.id}
          likeCount={nonprofit.likesInfo?.count || null}
          loggedInUserLikes={nonprofit.likesInfo?.hasLoggedInUserLiked || null}
        />
      </ShareAndLikeContainer>
      <PrimaryActionsContainer>
        {supporterInfo && supporterInfo.numSupporters > 0 && (
          <Button
            data-tname={"SuggestedNonprofitCard--supporterCount"}
            data-action={ClickAction.SUPPORTERS}
            size={ButtonSize.SMALL}
            role={ButtonRole.TEXT_ONLY}
            onClick={{
              kind: ButtonTargetKind.LINK,
              to: getRoutePath({
                format: URLFormat.RELATIVE,
                name: ClientRouteName.NONPROFIT_OR_CAUSE,
                tokens: { nonprofitSlug: primarySlug },
              }),
              onClick: (e) => e.stopPropagation(), // TODO: remove this to track feed clicks?
            }}
          >
            <SupportedBy numSupporters={supporterInfo.numSupporters} />
          </Button>
        )}
        {!hidePrimaryAction && (
          <DonateButton
            data-tname="SuggestedNonprofitCard--donate"
            data-action={ClickAction.DONATE}
            donationsEnabled={nonprofit.donationsEnabled}
            primarySlug={nonprofit.primarySlug}
          >
            Donate
          </DonateButton>
        )}
      </PrimaryActionsContainer>
    </FeedCardActionsContainer>
  );

  return (
    <NewNonprofitCard
      causeCategoryBackgroundColor
      data-feed-feedid={feedId}
      data-feed-itemid={nonprofit.id}
      data-feed-itemtype={FeedItemType.NONPROFIT_RECOMMENDATION}
      nonprofit={nonprofit}
      customHeader={
        tag && (
          <SuggestedNonprofitCardHeader
            tag={tag}
            text={headerText}
            hideCause={hideCauseText}
          />
        )
      }
      customFooter={footer}
    />
  );
};
