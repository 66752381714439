import { StatsigClient } from "@statsig/js-client";

import { logger } from "src/utility/logger";

let statsigClient: StatsigClient;

const getStatsigClient = () => {
  if (!statsigClient) {
    logger.error({ message: "Statsig client not initialized" });
    return StatsigClient.instance();
  }
  return statsigClient;
};

const setStatsigClient = (client: StatsigClient) => {
  statsigClient = client;
};

export { getStatsigClient, setStatsigClient };
