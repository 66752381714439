import React, { createContext, useReducer } from "react";

export enum AbTestActionType {
  ADD_EXPOSURE = "ADD_EXPOSURE",
}

interface AddExposure {
  type: AbTestActionType.ADD_EXPOSURE;
  layer: string;
}

type AbTestAction = AddExposure;

type AbTestState = {
  exposures: Set<string>;
};
//#endregion

const initialState: AbTestState = {
  exposures: new Set(),
};

function abTestReducer(state: AbTestState, action: AbTestAction) {
  const newState = state;
  const { type } = action;
  switch (type) {
    case AbTestActionType.ADD_EXPOSURE:
      newState.exposures.add(action.layer);
      break;
  }
  return newState;
}

export const AbTestContext = createContext<AbTestState>(initialState);

export let dispatchAbTestAction: React.Dispatch<AbTestAction>;

/**
 * A simple context that tracks the ab-tests that a user has been exposed to.
 * This allows us to do things like record all the ab-tests associated with a
 * user when they make a donation.
 */
export const AbTestProvider: React.FCC = ({ children }) => {
  const [abTestState, abTestDispatcher] = useReducer(
    abTestReducer,
    initialState
  );
  dispatchAbTestAction = abTestDispatcher;

  return (
    <AbTestContext.Provider value={abTestState}>
      {children}
    </AbTestContext.Provider>
  );
};
