import {
  Button,
  ButtonRole,
  ButtonSize,
  ButtonTargetKind,
} from "@components/Button";
import { PasswordInput } from "@components/PasswordInput";
import { TextInput, TextInputType } from "@components/TextInput";
import { getEmailReceiptMessage } from "@components/donate/DonateV3/PaymentProcess/pages/DonationThankYou";
import { CreateOrUpdateDonationResult } from "@components/donate/DonateV3/types";
import { SecondaryText } from "@components/textHelpers";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Controller, useForm } from "react-hook-form";

import { ABTestingId } from "@every.org/common/src/codecs/entities";
import { spacing } from "@every.org/common/src/display/spacing";
import {
  EmailAddress,
  PaymentMethod,
} from "@every.org/common/src/entity/types";
import { removeUndefinedValues } from "@every.org/common/src/helpers/objectUtilities";
import {
  createMeRouteSpec,
  MIN_PASSWORD_LENGTH,
} from "@every.org/common/src/routes/me";

import {
  emailAuth0Login,
  EmailAuth0LoginStatus,
} from "src/context/AuthContext/actions";
import { getMessageForError } from "src/errors";
import { lightBgThemeCss } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { horizontalStackCss, verticalStackCss } from "src/theme/spacing";
import { FontWeight, textSizeCss } from "src/theme/text";
import { useStatSigLayer } from "src/utility/abtesting";
import { queryApi } from "src/utility/apiClient";
import { getUTMValuesFromCookie } from "src/utility/cookies";
import { validatePassword } from "src/utility/signup/validator";
import { getWindow } from "src/utility/window";

export const LabeledInputContainer = styled.div`
  ${verticalStackCss.s}
`;

export const SocialButtonsContainer = styled.div`
  ${cssForMediaSize({
    max: MediaSize.X_SMALL,
    css: verticalStackCss.m,
  })}
  ${cssForMediaSize({
    min: MediaSize.SMALL,
    css: horizontalStackCss.s,
  })}
`;

export const BottomButtonsContainer = styled.div`
  ${verticalStackCss.m};
  ${cssForMediaSize({
    min: MediaSize.MEDIUM_SMALL,
    css: css`
      align-items: center;
    `,
  })}
  padding-bottom: ${spacing.m};
`;

const ThankYouMessage = ({
  paymentMethod,
  isGuestWithExistingEmail,
}: {
  paymentMethod: PaymentMethod;
  isGuestWithExistingEmail?: boolean;
}) => {
  const message = useStatSigLayer(
    "create_account_msg_layer",
    isGuestWithExistingEmail
  ).get("message", "Now create an account to connect with your giving.");

  return (
    <div css={[verticalStackCss.xxs]}>
      <strong
        css={css`
          ${textSizeCss.xl}
          font-weight: ${FontWeight.BOLD};
        `}
      >
        Thank you! 🎉
      </strong>{" "}
      <SecondaryText>
        {getEmailReceiptMessage(paymentMethod)}{" "}
        {isGuestWithExistingEmail
          ? "Now log in to your existing account to add this donation to your history."
          : message}
      </SecondaryText>
    </div>
  );
};

export interface SignupStepProps {
  createOrUpdateDonationResult?: CreateOrUpdateDonationResult;
  userEmail: string;
  goToGivelistUrl: string | null;
  postDonationAction: () => void;
  paymentMethod: PaymentMethod;
  abTestingId?: ABTestingId | undefined;
  isGuestWithExistingEmail?: boolean;
}

export const Signup = ({
  userEmail,
  // createOrUpdateDonationResult,
  goToGivelistUrl,
  postDonationAction,
  paymentMethod,
  abTestingId,
}: SignupStepProps) => {
  // TODO make sure to do the postDonationAction if the user chooses not to signup
  const form = useForm<{ email: EmailAddress; password: string }>({
    defaultValues: {
      email: undefined,
      password: undefined,
    },
    criteriaMode: "all",
  });

  const { control, handleSubmit, setError } = form;

  const skipSignupButtonCopy = useStatSigLayer(
    "exit_copy_layer",
    !!goToGivelistUrl
  ).get("exit_copy", false);

  function validatePasswordExists(password: string | undefined) {
    return (password && password.length > 0) || "Please set a password.";
  }

  function validatePasswordConstraints(password: string | undefined) {
    return validatePassword(password);
  }

  const submit = handleSubmit(async (formValues) => {
    const { password } = formValues;
    const location = getWindow()?.location;

    try {
      await queryApi(createMeRouteSpec, {
        body: removeUndefinedValues({
          email: userEmail,
          password,
          metadata: getUTMValuesFromCookie(),
          guestToken:
            (location &&
              new URLSearchParams(location.search).get("guestToken")) ||
            undefined,
        }),
        routeTokens: {},
        queryParams: {},
      });
      const auth0Result = await emailAuth0Login({
        email: userEmail,
        password,
        redirectUrl: undefined,
        loginAfterDonationFlow: true,
      });
      if (auth0Result.status !== EmailAuth0LoginStatus.SUCCESS) {
        setError("password", {
          type: "string",
          message: auth0Result.errorMessage,
        });
        return false;
      }
    } catch (e) {
      if (e instanceof Error) {
        setError("password", {
          type: "string",
          message: getMessageForError(e),
        });
      }
      return false;
    }
    return true;
  });

  return (
    <form onSubmit={submit} css={{ width: "100%" }}>
      <div
        css={[
          lightBgThemeCss,
          css`
            ${verticalStackCss.xxl};
            position: relative;
            ${cssForMediaSize({
              min: MediaSize.LARGE,
              css: css`
                padding: ${spacing.l};
              `,
            })};
          `,
        ]}
      >
        <ThankYouMessage paymentMethod={paymentMethod} />
        <div css={verticalStackCss.l}>
          <TextInput
            type={TextInputType.EMAIL}
            labelText="Email"
            name="email"
            id="email"
            data-tname="email"
            value={userEmail}
            autoComplete="email"
            disabled
            css={css`
              display: none;
            `}
          />
          <div css={verticalStackCss.none}>
            <Controller
              control={control}
              name="password"
              rules={{
                validate: {
                  validatePasswordExists,
                  validatePasswordConstraints,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <LabeledInputContainer>
                  <h5>Set a password</h5>
                  <PasswordInput
                    autoComplete="new-password"
                    name="password"
                    data-tname="password"
                    placeholder={`${MIN_PASSWORD_LENGTH}+ characters`}
                    onChange={onChange}
                    value={value}
                    validationStatus={
                      form.formState.errors.password?.message
                        ? {
                            success: false,
                            message: form.formState.errors.password.message,
                          }
                        : undefined
                    }
                    required
                  />
                </LabeledInputContainer>
              )}
            />
            <BottomButtonsContainer>
              {goToGivelistUrl && (
                <Button
                  data-tname="goToGivelistButton"
                  onClick={{
                    kind: ButtonTargetKind.LINK,
                    to: goToGivelistUrl,
                  }}
                  role={ButtonRole.TEXT_ONLY}
                >
                  Return&nbsp;to&nbsp;giveli.st
                </Button>
              )}
              <Button
                data-tname="donate--createAccount"
                role={ButtonRole.PRIMARY}
                size={ButtonSize.MEDIUM}
                onClick={{
                  kind: ButtonTargetKind.SUBMIT,
                }}
                disabled={form.formState.isSubmitting}
                submitting={form.formState.isSubmitting}
              >
                Create account
              </Button>
              {skipSignupButtonCopy && (
                <Button
                  data-tname="donate--skipSignup"
                  role={ButtonRole.TEXT_SECONDARY}
                  size={ButtonSize.MEDIUM}
                  onClick={{
                    kind: ButtonTargetKind.FUNCTION,
                    action: postDonationAction,
                  }}
                >
                  {skipSignupButtonCopy}
                </Button>
              )}
            </BottomButtonsContainer>
          </div>
        </div>
      </div>
    </form>
  );
};
