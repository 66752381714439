import {
  StatsigProvider as LibStatsigProvider,
  StatsigClient,
} from "@statsig/react-bindings";
import { StatsigSessionReplayPlugin } from "@statsig/session-replay";
import React, { useContext, useEffect, useMemo, useRef } from "react";

import { ABTestingId } from "@every.org/common/src/codecs/entities";
import { assertEnvPresent } from "@every.org/common/src/helpers/getEnv";

import { AuthContext } from "src/context/AuthContext";
import { getTestingId } from "src/utility/abtesting";
import { STATSIG_PROXY_URL } from "src/utility/statsig";
import { setStatsigClient } from "src/utility/statsigClient";

type StatsigProviderProps = {
  children: React.ReactNode;
  statsigInitProps?: Record<string, unknown>;
  abTestingId?: string;
};
export const StatsigProvider = ({
  children,
  statsigInitProps = {},
  abTestingId = undefined,
}: StatsigProviderProps) => {
  const authState = useContext(AuthContext);
  const testingId = getTestingId({
    authState,
    ssrTestingId: abTestingId,
  }) as ABTestingId;

  // Note, in dev we see a warning about Statsig being initialized twice This
  // shouldn't happen in prod, as React renders everything twice in dev due to
  // Strict mode
  // https://legacy.reactjs.org/docs/strict-mode.html#detecting-unexpected-side-effects
  const clientRef = useRef<StatsigClient | null>();

  const client = useMemo(() => {
    if (clientRef.current) {
      return clientRef.current;
    }

    const inst = new StatsigClient(
      assertEnvPresent(
        process.env.NEXT_PUBLIC_STATSIG_PUBLIC_API_KEY,
        "STATSIG_API_KEY"
      ),
      { userID: testingId },
      {
        environment: {
          tier: process.env.NEXT_PUBLIC_ENVIRONMENT_NAME,
        },
        networkConfig: {
          // Allows us to proxy statsig requests through our own domain
          api: STATSIG_PROXY_URL,
        },
        plugins: [new StatsigSessionReplayPlugin()],
      }
    );
    clientRef.current = inst;
    setStatsigClient(inst);
    if (statsigInitProps) {
      inst.dataAdapter.setData(JSON.stringify(statsigInitProps));
    }
    inst.initializeSync();
    return inst;
  }, [statsigInitProps, testingId]);

  useEffect(() => {
    const currentUserId = client?.getContext().user.userID;
    if (currentUserId && testingId !== currentUserId) {
      client.updateUserAsync({
        userID: testingId,
      });
      client.initializeAsync();
    }
  }, [testingId, client]);

  if (client) {
    return <LibStatsigProvider client={client}>{children}</LibStatsigProvider>;
  }

  return <React.Fragment>{children}</React.Fragment>;
};
