import {
  ButtonRole,
  ButtonSize,
  Button,
  ButtonTargetKind,
} from "@components/Button";
import { FundShareButton } from "@components/FundShareButton";
import { Modal, DESKTOP_MODAL_MIN_WIDTH } from "@components/Modal";
import { SupportedBy } from "@components/SupportedBy";
import { NonprofitSupporters } from "@components/SupportersList/NonprofitSupporters";
import {
  FeedCardActionsContainer,
  PrimaryActionsContainer,
  ShareAndLikeContainer,
} from "@components/feed/DonationCardActions";
import { LikeButtonWithCounter } from "@components/feed/LikeButton";
import { DonateButton } from "@components/layout/DonateButton";
import { css } from "@emotion/react";
import React, { useState } from "react";

import { spacing } from "@every.org/common/src/display/spacing";
import { LikeableType } from "@every.org/common/src/entity/types";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { ClickAction } from "src/utility/analytics";

interface CreatedFundCardActionsProps {
  fund: ContextNonprofit;
  numSupporters?: number;
}

export const CreatedFundCardActions: React.FCC<CreatedFundCardActionsProps> = ({
  fund,
}) => {
  const [showSupportersModal, setShowSupportersModal] = useState(false);

  return (
    <FeedCardActionsContainer>
      <ShareAndLikeContainer>
        <FundShareButton
          fund={fund}
          data-tname="createdFundCard-shareButton"
          data-action={ClickAction.SHARE}
          hideText
          buttonCss={{ padding: spacing.xxs }}
          role={ButtonRole.TEXT_ONLY}
        />
        <LikeButtonWithCounter
          data-tname="createdFundCard-likeButton"
          data-action={ClickAction.LIKE}
          role={ButtonRole.TEXT_ONLY}
          size={ButtonSize.SMALL}
          type={LikeableType.FUND}
          id={fund.id}
          loggedInUserLikes={fund.likesInfo?.hasLoggedInUserLiked || null}
          likeCount={fund.likesInfo?.count || null}
        />
      </ShareAndLikeContainer>
      <PrimaryActionsContainer>
        {/* Don't show num joined if the button is long */}
        {fund.supporterCount && fund.supporterCount > 0 ? (
          <React.Fragment>
            <Button
              data-tname="suggestedFundNumSupporters"
              data-action={ClickAction.SUPPORTERS}
              role={ButtonRole.TEXT_ONLY}
              onClick={{
                kind: ButtonTargetKind.FUNCTION,
                action: () => setShowSupportersModal(true),
              }}
            >
              <SupportedBy numSupporters={fund.supporterCount} />
            </Button>
            <Modal
              isOpen={showSupportersModal}
              headerText="Donors"
              showHeader
              onRequestClose={() => setShowSupportersModal(false)}
            >
              <NonprofitSupporters
                css={css`
                  /* Do not let it be so wide */
                  max-width: ${DESKTOP_MODAL_MIN_WIDTH};
                `}
                nonprofitId={fund.id}
                supporterCount={fund.supporterCount}
              />
            </Modal>
          </React.Fragment>
        ) : undefined}
        <DonateButton
          role={ButtonRole.PRIMARY}
          data-tname="suggestedFundDonateButton"
          data-action={ClickAction.DONATE}
          primarySlug={fund.primarySlug}
          donationsEnabled={fund.donationsEnabled}
        >
          Donate
        </DonateButton>
      </PrimaryActionsContainer>
    </FeedCardActionsContainer>
  );
};
