import { Loading } from "@components/LoadingIndicator";
import {
  ChariotButtonHTMLAttributes,
  ChariotButtonHTMLElement,
  ChariotProps,
  ChariotResponseStatus,
} from "@components/donate/DonateV3/PaymentProcess/components/Chariot/types";
import {
  paymentProcessRouteNameToPathMap,
  PaymentProcessRouteName,
} from "@components/donate/DonateV3/PaymentProcess/components/PaymentProcessLink";
import { archiveDonation as archiveDonationHelper } from "@components/donate/DonateV3/PaymentProcess/helpers";
import { useSyncPaymentMethod } from "@components/donate/DonateV3/PaymentProcess/useSyncPaymentMethod";
import { DONATE_FORM_ERROR } from "@components/donate/DonateV3/types";
import styled from "@emotion/styled";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DonationFlowPaymentOption } from "@every.org/common/src/entity/types";
import { currencyValueToMinimumDenominationAmount } from "@every.org/common/src/helpers/currency";
import { removeUndefinedOrNullValues } from "@every.org/common/src/helpers/objectUtilities";
import { chariotSuccessfulFrontendRouteSpec } from "@every.org/common/src/routes/donation";

import { useLoggedInOrGuestUserOrUndefined } from "src/context/AuthContext/hooks";
import { ScriptLoadStatus, useScript } from "src/hooks/useScript";
import { colorCssVars } from "src/theme/color";
import { spacing } from "src/theme/spacing";
import { queryApi } from "src/utility/apiClient";
import { logger } from "src/utility/logger";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      "chariot-connect": ChariotButtonHTMLAttributes;
    }
  }
}

const COMPONENT_ID = "chariot";

const CHARIOT_SCRIPT_URL = "https://cdn.givechariot.com/chariot-connect.umd.js";
const CHARIOT_CID =
  process.env.NEXT_PUBLIC_ENVIRONMENT_NAME === "production"
    ? "live_cdc0971b-9fba-4f59-937e-4d296a3524c6"
    : "test_70217e0bd8b97893095bd37bcd8c852d2bf0f0f4de64dda497d6eb1e2b1c0579";

export function Chariot({
  form,
  formContext,
  createOrUpdateDonationResult,
}: ChariotProps) {
  const loggedInUser = useLoggedInOrGuestUserOrUndefined();
  const scriptStatus = useScript(CHARIOT_SCRIPT_URL);

  const [mounted, setMounted] = useState(false);
  const chariotRef = useRef<ChariotButtonHTMLElement | null>(null);

  const navigate = useNavigate();

  const [error, setError] = useState<string>();

  useSyncPaymentMethod({
    paymentOption: DonationFlowPaymentOption.DAF,
    form,
    formContext,
  });

  const archiveDonation = useCallback(
    async (errorMessage?: string) => {
      const donationId = createOrUpdateDonationResult?.donation.id;
      await archiveDonationHelper(donationId, formContext);
      errorMessage &&
        form.setError(DONATE_FORM_ERROR, {
          type: "string",
          message: errorMessage,
        });
    },
    [createOrUpdateDonationResult?.donation.id, form, formContext]
  );

  // Pass data to Chariot
  const handleChariotResponse = useCallback(
    async (e: Event) => {
      switch (e.type) {
        case ChariotResponseStatus.CHARIOT_SUCCESS: {
          setError(undefined);
          // Save metadata and transition to PENDING?
          const detail = (e as unknown as any).detail;
          const grant =
            (detail.grant as unknown as any) ||
            (detail.grantIntent as unknown as any) ||
            {};
          const body = {
            grantId: grant.id, // hopefully it will be added back
            workflowSessionId: detail.workflowSessionId,
            amount: grant.amount,
            userFriendlyId: grant.userFriendlyId,
            fundId: grant.fundId,
            externalGrantId: grant.externalGrantId,
            status: grant.status,
            fee: grant.feeAmount,
            coveredFees: grant.coveredFees,
          } as unknown as {
            workflowSessionId: string | undefined;
            amount: number | undefined;
            userFriendlyId: string | undefined;
            fundId: string | undefined;
            externalGrantId: string | undefined;
            status: string | undefined;
            fee: number | undefined;
            coveredFees: boolean | undefined;
          };
          if (createOrUpdateDonationResult?.donationCharge?.id) {
            try {
              await queryApi(chariotSuccessfulFrontendRouteSpec, {
                routeTokens: {
                  id: createOrUpdateDonationResult?.donationCharge?.id,
                },
                queryParams: {},
                body,
              });
            } catch (error) {
              logger.error({
                message: "error recording chariot's success",
                data: {
                  ...body,
                  chargeId: createOrUpdateDonationResult?.donationCharge?.id,
                },
                error,
              });
            }
          }
          navigate(
            paymentProcessRouteNameToPathMap[PaymentProcessRouteName.THANK_YOU]
          );
          break;
        }
        case ChariotResponseStatus.CHARIOT_ERROR:
          await archiveDonation();
          setError("An error ocurred in Give Chariot's DAF donation modal.");
          break;
        case ChariotResponseStatus.CHARIOT_EXIT:
          await archiveDonation();
          setError(undefined);
          break;
      }
    },
    [
      archiveDonation,
      navigate,
      createOrUpdateDonationResult?.donationCharge?.id,
    ]
  );

  const handleOnDonationRequest = useCallback(() => {
    if (!createOrUpdateDonationResult.donationCharge) {
      return;
    }

    const data = removeUndefinedOrNullValues({
      anonymous: !!(loggedInUser?.firstName || loggedInUser?.lastName),
      amount:
        currencyValueToMinimumDenominationAmount({
          value: createOrUpdateDonationResult.donationCharge.value,
        }) + createOrUpdateDonationResult.donationCharge.tipAmount,
      email: loggedInUser?.email,
      firstName: loggedInUser?.firstName,
      lastName: loggedInUser?.lastName,
      metadata: {
        donationId: createOrUpdateDonationResult.donation.id,
      },
    });
    return data;
  }, [
    createOrUpdateDonationResult,
    loggedInUser?.email,
    loggedInUser?.firstName,
    loggedInUser?.lastName,
  ]);

  useEffect(() => {
    const chariot = chariotRef.current;
    if (!mounted || !chariot) {
      return undefined;
    }

    chariot.onDonationRequest(handleOnDonationRequest);
    chariot.addEventListener(
      ChariotResponseStatus.CHARIOT_SUCCESS,
      handleChariotResponse
    );
    chariot.addEventListener(
      ChariotResponseStatus.CHARIOT_ERROR,
      handleChariotResponse
    );
    chariot.addEventListener(
      ChariotResponseStatus.CHARIOT_EXIT,
      handleChariotResponse
    );

    // unsubscribe
    return () => {
      chariot.removeEventListener(
        ChariotResponseStatus.CHARIOT_SUCCESS,
        handleChariotResponse
      );
      chariot.removeEventListener(
        ChariotResponseStatus.CHARIOT_ERROR,
        handleChariotResponse
      );
      chariot.removeEventListener(
        ChariotResponseStatus.CHARIOT_EXIT,
        handleChariotResponse
      );
    };
  }, [handleChariotResponse, handleOnDonationRequest, mounted, form]);

  const onButtonMounted = (node: ChariotButtonHTMLElement | null) => {
    if (node) {
      setMounted(true);
      chariotRef.current = node;
    }
  };

  if (scriptStatus === ScriptLoadStatus.LOADING) {
    return <Loading />;
  }

  if (scriptStatus === ScriptLoadStatus.ERROR) {
    return null;
  }

  return (
    <div css={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <chariot-connect
        ref={onButtonMounted}
        id={COMPONENT_ID}
        cid={CHARIOT_CID}
        style={{
          width: "214px",
          display: "flex",
          alignItems: "center",
          height: "56px",
        }}
      />
      {error && <ErrorBox>{error}</ErrorBox>}
    </div>
  );
}

const ErrorBox = styled.div`
  padding: ${spacing.m};
  border-radius: 0;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  background: var(${colorCssVars.input.background.default});
  &:not(:focus-within):hover {
    background: var(${colorCssVars.input.background.hover});
  }
  &:focus-within {
    border: 1px solid var(${colorCssVars.input.border.error});
    box-shadow: 0px 0px 0px 2px var(${colorCssVars.input.outline.error});
    background: var(${colorCssVars.input.background.focus});
  }

  border: 1px solid var(${colorCssVars.input.border.error});
`;
