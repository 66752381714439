import * as t from "io-ts";
import { UUID as uuidCodec } from "io-ts-types/UUID";

import { stringEnumValues, stringEnumCodec } from "../../codecs";
import { currencyAmountCodec, currencyValueCodec } from "../../codecs/currency";
import { dateFromStringCodec } from "../../codecs/date";
import { usernameCodec } from "../../codecs/username";
import { DonationFrequency, FundraiserType } from "../../entity/types";

import { AchievementKey } from "./achievement";

/**
 * Every possible notification that the application can send
 */
export enum NotificationType {
  /**
   * Recurring donation is going to execute soon
   */
  RECURRING_DONATION_PENDING = "RECURRING_DONATION_PENDING",
  /**
   * Recurring donation has been executed
   */
  RECURRING_DONATION_EXECUTED = "RECURRING_DONATION_EXECUTED",
  /**
   * One-time donation has been executed
   */
  ONE_TIME_DONATION_EXECUTED = "ONE_TIME_DONATION_EXECUTED",
  /**
   * Another user has requested to follow you
   *
   * Only occurs if you require approval for follows to your account.
   */
  FOLLOW_REQUESTED = "FOLLOW_REQUESTED",
  /**
   * Your request to follow another user has been accepted
   *
   * Only occurs if you follow an account that requires approval for follows.
   */
  FOLLOW_APPROVED = "FOLLOW_APPROVED",
  /**
   * Somebody has followed you.
   *
   * Only occurs if you don't require approval for follows.
   */
  FOLLOWED = "FOLLOWED",
  /**
   * A donation you've made has received a like
   */
  DONATION_LIKED = "DONATION_LIKED",
  /**
   * A donation you've made has been "boosted"/joined.
   */
  DONATION_BOOSTED = "DONATION_BOOSTED",
  /**
   * A donation you've made has been matched
   */
  DONATION_MATCHED = "DONATION_MATCHED",
  /**
   * You received a gift card.
   */
  GIFT_CARD_RECEIVED = "GIFT_CARD_RECEIVED",
  /**
   * User claimed an achievement
   */
  ACHIEVEMENT_CLAIMED = "ACHIEVEMENT_CLAIMED",
  /**
   * A nonprofit edit you've requested has been accepted
   */
  EDIT_ACCEPTED = "EDIT_ACCEPTED",
  /**
   * A fundraiser you created received a donation
   */
  FUNDRAISER_DONATION_RECEIVED = "FUNDRAISER_DONATION_RECEIVED",
}

/**
 * Media by which notifications can be surfaced
 */
export enum NotificationMedium {
  EMAIL = "EMAIL",
  IN_WEB_APP = "IN_WEB_APP",
}

/**
 * Default values for all notification types for each notification medium
 */
export const defaultNotificationPreferences: {
  [key in NotificationMedium]: {
    [key in NotificationType]: boolean;
  };
} = {
  [NotificationMedium.IN_WEB_APP]: {
    [NotificationType.DONATION_LIKED]: true,
    [NotificationType.DONATION_BOOSTED]: true,
    [NotificationType.DONATION_MATCHED]: true,
    [NotificationType.FOLLOW_APPROVED]: true,
    [NotificationType.FOLLOW_REQUESTED]: true,
    [NotificationType.FOLLOWED]: true,
    [NotificationType.RECURRING_DONATION_EXECUTED]: true,
    [NotificationType.RECURRING_DONATION_PENDING]: true,
    [NotificationType.ONE_TIME_DONATION_EXECUTED]: false,
    [NotificationType.GIFT_CARD_RECEIVED]: true,
    [NotificationType.ACHIEVEMENT_CLAIMED]: true,
    [NotificationType.EDIT_ACCEPTED]: true,
    [NotificationType.FUNDRAISER_DONATION_RECEIVED]: true,
  },
  [NotificationMedium.EMAIL]: {
    [NotificationType.DONATION_LIKED]: false,
    [NotificationType.DONATION_BOOSTED]: true,
    [NotificationType.DONATION_MATCHED]: false,
    [NotificationType.FOLLOW_APPROVED]: true,
    [NotificationType.FOLLOW_REQUESTED]: true,
    [NotificationType.FOLLOWED]: true,
    [NotificationType.RECURRING_DONATION_EXECUTED]: true,
    [NotificationType.RECURRING_DONATION_PENDING]: true,
    [NotificationType.ONE_TIME_DONATION_EXECUTED]: true,
    [NotificationType.GIFT_CARD_RECEIVED]: true,
    [NotificationType.ACHIEVEMENT_CLAIMED]: false,
    [NotificationType.EDIT_ACCEPTED]: false,
    [NotificationType.FUNDRAISER_DONATION_RECEIVED]: true,
  },
};

export const notificationPublicUserMetadataCodec = t.intersection([
  t.type({
    id: uuidCodec,
  }),
  t.partial({
    firstName: t.string,
    lastName: t.string,
    username: usernameCodec,
    profileImageCloudinaryId: t.string,
  }),
]);

export const notificationDonationFrequencyCodec = stringEnumCodec({
  name: "DonationFrequency",
  enumObject: DonationFrequency,
});

/**
 * Metadata about a user to display and store in a notification.
 *
 * The data stored in the database is, as of now, normalized.
 */
export type NotificationPublicUserMetadata = t.TypeOf<
  typeof notificationPublicUserMetadataCodec
>;

export const notificationUserMetadataCodec = t.intersection([
  notificationPublicUserMetadataCodec,
  t.type({
    email: t.string,
  }),
]);

export type NotificationUserMetadata = t.TypeOf<
  typeof notificationUserMetadataCodec
>;

export const notificationNonprofitMetadataCodec = t.type({
  id: uuidCodec,
  name: t.string,
  logoCloudinaryId: t.union([t.string, t.null]),
  slug: t.string,
});

export const notificationFundraiserMetadataCodec = t.type({
  id: uuidCodec,
  slug: t.string,
  title: t.string,
  type: stringEnumCodec({
    name: "FundraiserType",
    enumObject: FundraiserType,
  }),
  metadata: t.union([
    t.partial({
      orgName: t.string,
      avatarCloudinaryId: t.string,
    }),
    t.null,
  ]),
});

/**
 * Metadata about a nonprofit to display and store in a notification.
 *
 * The data stored in the database is, as of now, normalized.
 */
export type NotificationNonprofitMetadata = t.TypeOf<
  typeof notificationNonprofitMetadataCodec
>;

export const recurringDonationNotificationDetailsCodec = t.intersection([
  t.type({
    recurringDonationId: uuidCodec, // TODO(#4618): Rename to donationId
    donationId: uuidCodec, // TODO(#4618): Rename to donationChargeId
    amount: currencyAmountCodec,
    paidAmount: currencyAmountCodec,
    creditAmount: currencyAmountCodec,
    nonprofit: notificationNonprofitMetadataCodec,
    user: notificationUserMetadataCodec,
    scheduled: dateFromStringCodec,
    frequency: notificationDonationFrequencyCodec,
  }),
  t.partial({
    fundraiser: notificationFundraiserMetadataCodec,
    executedDate: dateFromStringCodec,
  }),
]);

export const oneTimeDonationNotificationDetailsCodec = t.intersection([
  t.type({
    donationId: uuidCodec,
    amount: currencyAmountCodec,
    paidAmount: currencyAmountCodec,
    creditAmount: currencyAmountCodec,
    nonprofit: notificationNonprofitMetadataCodec,
    user: notificationUserMetadataCodec,
    initiatedDate: dateFromStringCodec,
  }),
  t.partial({ fundraiser: notificationFundraiserMetadataCodec }),
]);

export const followNotificationBaseDataCodec = t.type({
  userFollowId: t.string,
});

export const donationLikedNotificationDetailsCodec = t.type({
  donationId: uuidCodec,
  userWhoLiked: notificationPublicUserMetadataCodec,
  nonprofitDonatedTo: notificationNonprofitMetadataCodec,
});

export const donationBoostNotificationDetailsCodec = t.intersection([
  t.type({
    boostDonationId: uuidCodec,
    userWhoBoosted: notificationPublicUserMetadataCodec,
    nonprofitDonatedTo: notificationNonprofitMetadataCodec,
  }),
  t.partial({
    // We previously had these as strings and didn't migrate
    boostDonationShortId: t.union([t.number, t.string]),
    fundraiserDonatedTo: notificationFundraiserMetadataCodec,
  }),
]);

export const donationMatchedNotificationDetailsCodec = t.intersection([
  t.type({
    amountMatched: currencyValueCodec,
    userWhoMatched: t.union([notificationPublicUserMetadataCodec, t.null]),
    nonprofit: notificationNonprofitMetadataCodec,
  }),
  t.partial({ fundraiser: notificationFundraiserMetadataCodec }),
]);

const followedNotificationDetailsCodec = t.intersection([
  followNotificationBaseDataCodec,
  t.type({
    followerUser: notificationPublicUserMetadataCodec,
  }),
]);

const followRequestedNotificationDetailsCodec =
  followedNotificationDetailsCodec;
const followApprovedNotificationDetailsCodec = t.intersection([
  followNotificationBaseDataCodec,
  t.type({
    followedUser: notificationPublicUserMetadataCodec,
  }),
]);

const giftCardReceivedNotificationDetailsCodec = t.intersection([
  t.type({ amount: currencyAmountCodec }),
  t.partial({
    message: t.union([t.string, t.null]),
    fromUser: notificationUserMetadataCodec,
  }),
]);

const achivementUserAchievedNotificationData = t.intersection([
  t.union([
    t.intersection([
      t.type({
        achievementKey: t.union([
          t.literal(AchievementKey.FIRST_DONATION),
          t.literal(AchievementKey.FIRST_RECURRING),
          t.literal(AchievementKey.GIVEBLCK),
          t.literal(AchievementKey.HOOP_FOR_ALL),
        ]),
        userId: uuidCodec,
        toNonprofit: notificationNonprofitMetadataCodec,
      }),
      t.partial({ fundraiser: notificationFundraiserMetadataCodec }),
    ]),
    t.type({
      achievementKey: t.literal(AchievementKey.DONATE_WITH_BANK),
    }),
    t.type({
      achievementKey: t.literal(AchievementKey.LIKED_DONATION),
      donation: t.intersection([
        t.type({
          donorUsername: t.union([t.string, t.null]),
          toNonprofitSlug: t.string,
          shortId: t.number,
        }),
        t.partial({ fundraiser: notificationFundraiserMetadataCodec }),
      ]),
      likedDonorsName: t.union([t.string, t.null]),
    }),
    t.type({
      achievementKey: t.literal(AchievementKey.FOLLOW_USER),
      followedUser: t.type({
        fullName: t.union([t.string, t.null]),
        username: t.union([t.string, t.null]),
      }),
    }),
  ]),
  t.partial({
    incentiveAmount: t.union([t.number, t.null]),
  }),
]);

const editAcceptedNotificationDetailsCodec = t.type({
  nonprofit: notificationNonprofitMetadataCodec,
});

const fundraiserDonationReceivedNotificationDetailsCodec = t.type({
  fundraiser: notificationFundraiserMetadataCodec,
  nonprofit: notificationNonprofitMetadataCodec,
});

/**
 * Mapping from notification type to metadata about that notification
 */
export const notificationDataCodec = t.union([
  t.type({
    type: t.literal(NotificationType.RECURRING_DONATION_PENDING),
    details: recurringDonationNotificationDetailsCodec,
  }),
  t.type({
    type: t.literal(NotificationType.RECURRING_DONATION_EXECUTED),
    details: recurringDonationNotificationDetailsCodec,
  }),
  t.type({
    type: t.literal(NotificationType.ONE_TIME_DONATION_EXECUTED),
    details: oneTimeDonationNotificationDetailsCodec,
  }),
  t.type({
    type: t.literal(NotificationType.FOLLOW_REQUESTED),
    details: followRequestedNotificationDetailsCodec,
  }),
  t.type({
    type: t.literal(NotificationType.FOLLOW_APPROVED),
    details: followApprovedNotificationDetailsCodec,
  }),
  t.type({
    type: t.literal(NotificationType.FOLLOWED),
    details: followedNotificationDetailsCodec,
  }),
  t.type({
    type: t.literal(NotificationType.DONATION_LIKED),
    details: donationLikedNotificationDetailsCodec,
  }),
  t.type({
    type: t.literal(NotificationType.DONATION_BOOSTED),
    details: donationBoostNotificationDetailsCodec,
  }),
  t.type({
    type: t.literal(NotificationType.DONATION_MATCHED),
    details: donationMatchedNotificationDetailsCodec,
  }),
  t.type({
    type: t.literal(NotificationType.GIFT_CARD_RECEIVED),
    details: giftCardReceivedNotificationDetailsCodec,
  }),
  t.type({
    type: t.literal(NotificationType.ACHIEVEMENT_CLAIMED),
    details: achivementUserAchievedNotificationData,
  }),
  t.type({
    type: t.literal(NotificationType.EDIT_ACCEPTED),
    details: editAcceptedNotificationDetailsCodec,
  }),
  t.type({
    type: t.literal(NotificationType.FUNDRAISER_DONATION_RECEIVED),
    details: fundraiserDonationReceivedNotificationDetailsCodec,
  }),
]);

export type NotificationData = t.TypeOf<typeof notificationDataCodec>;

/**
 * Exhaustive list of all notification types.
 *
 * Exhaustive because of type of defaultNotificationPreferences
 */
export const allNotificationTypes = stringEnumValues({
  enumObject: NotificationType,
});

/**
 * Exhaustive list of all notification media.
 *
 * Exhaustive because of type of defaultNotificationPreferences
 */
export const allNotificationMedia = stringEnumValues({
  enumObject: NotificationMedium,
});
