import { ButtonRole, ButtonSize, fullWidthButtonCss } from "@components/Button";
import { FGCNonprofitHeader } from "@components/FGC/FGCNonprofitHeader";
import { MobileActionButtonsContainer } from "@components/MobileActionButtonsContainer";
import { ParentNonprofitNote } from "@components/Nonprofit/ParentNonprofitNote";
import { NonprofitAnnouncement } from "@components/NonprofitAnnouncement";
import {
  getSupportNonprofitShareData,
  ShareButton,
} from "@components/ShareButton";
import { DefaultPageLayout } from "@components/layout/DefaultPageLayout";
import { DonateButton } from "@components/layout/DonateButton";
import { css } from "@emotion/react";
import React, { useContext, useEffect, useRef, useState } from "react";

import {
  FeedItemResponse,
  FundraiserResponse,
} from "@every.org/common/src/codecs/entities";
import { colorPalette } from "@every.org/common/src/display/palette";
import {
  GIVEWELL_NONPROFIT_ID,
  GIVEWELL_UNRESTRICTED_NONPROFIT_ID,
} from "@every.org/common/src/entity/constants";
import { NonprofitDisplayType } from "@every.org/common/src/entity/types";
import {
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";
import { constructCloudinaryUrl } from "@every.org/common/src/helpers/cloudinary";
import { removeUndefinedOrNullValues } from "@every.org/common/src/helpers/objectUtilities";

import { NonprofitFeed } from "src/components/Nonprofit/NonprofitFeed";
import { NonprofitSupportingFunds } from "src/components/Nonprofit/NonprofitSupportingFunds";
import NonprofitFundraisersList from "src/components/NonprofitFundraisersList";
import { Redirect } from "src/components/Redirect";
import { useJoinDonationParams } from "src/components/donate/DonateRouteModal";
import { DonateModalAction } from "src/components/donate/DonateV3/types";
import { PageSection } from "src/components/layout/PageSection";
// import { MyDonationsContext } from "src/context/MyDonationsContext";
import { NonprofitsContext } from "src/context/NonprofitsContext";
import { getNonprofitOrUndefined } from "src/context/NonprofitsContext/selectors";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import { useFallGivingChallengeActive } from "src/hooks/useFallGivingChallengeActive";
import { useNonprofitAnnouncement } from "src/hooks/useNonprofitAnnouncement";
import { useNonprofitMatchingCampaign } from "src/hooks/useNonprofitMatchingCampaign";
import { useOpenDonatePage } from "src/hooks/useOpenDonatePage";
import DonateV3Page from "src/pages/DonateV3";
import { BecomeAnAdminCard } from "src/pages/Nonprofit/BecomeAnAdminCard";
import { NonprofitPageV3AboutCard } from "src/pages/Nonprofit/NonprofitPageV3/NonprofitPageV3AboutCard";
import { NonprofitPageV3HeaderCard } from "src/pages/Nonprofit/NonprofitPageV3/NonprofitPageV3HeaderCard";
import { NonprofitPageV3RecentContributorsCard } from "src/pages/Nonprofit/NonprofitPageV3/NonprofitPageV3RecentContributorsCard";
// import { NonprofitPageV3YouAreSupporterCard } from "src/pages/Nonprofit/NonprofitPageV3/NonprofitPageV3YouAreSupporterCard";
import {
  largeScreenAnnouncementCss,
  pageGridCss,
  smallScreenAnnouncementCss,
} from "src/pages/Nonprofit/NonprofitPageV3/styles";
import { colorCssVars } from "src/theme/color";
import {
  MediaSize,
  cssForMediaSize,
  useMatchesScreenSize,
} from "src/theme/mediaQueries";
import {
  horizontalStackCss,
  spacing,
  verticalStackCss,
} from "src/theme/spacing";
import { useStatSigLayer } from "src/utility/abtesting";
import { ClientTrackingRoute, trackPageChange } from "src/utility/analytics";
import { nonprofitShareImageCloudinaryParams } from "src/utility/cloudinary/nonprofit";
import { NONPROFIT_ITEM_TYPES } from "src/utility/microdata";
import {
  DEFAULT_SHARE_IMAGE_CLOUDINARY_PARAMS,
  INSTAGRAM_POST_DIMENSIONS,
  OPENGRAPH_DIMENSIONS,
} from "src/utility/opengraph";

interface NonprofitPageV3Props {
  nonprofit: ContextNonprofit;
  contributorList: FeedItemResponse[];
  contributorListHasMore?: boolean;
  initialFundraisers?: {
    fundraisers: FundraiserResponse[];
    hasMore: boolean;
  };
}

export function NonprofitPageV3({
  nonprofit,
  contributorList,
  contributorListHasMore,
  initialFundraisers,
}: NonprofitPageV3Props) {
  const { push } = useEdoRouter();
  const nonprofitMatchingCampaign = useNonprofitMatchingCampaign({
    nonprofitId: nonprofit.id,
  });
  const nonprofitAnnouncement = useNonprofitAnnouncement(
    nonprofit,
    nonprofitMatchingCampaign
  );

  const hideFundraisers = initialFundraisers?.fundraisers?.length === 0;
  const [isSSR, setIsSSR] = useState(true);
  const headerDonateBlockRef = useRef<HTMLDivElement | null>(null);
  const {
    name,
    description,
    coverImageCloudinaryId,
    logoCloudinaryId,
    createdFundIds,
    endorserNonprofitIds,
  } = nonprofit;

  const showFundSection = createdFundIds && createdFundIds.length > 0;
  // eslint-disable-next-line no-restricted-syntax
  const isSmallScreen = useMatchesScreenSize({ max: MediaSize.MEDIUM_LARGE });
  const showMobileActionButtons = useStatSigLayer(
    "mobile_donate_button_layer",
    !isSmallScreen
  ).get("showButtons", false);

  useEffect(() => {
    setIsSSR(false);
  }, []);

  const openDonatePage = useOpenDonatePage();

  useEffect(() => {
    trackPageChange(ClientTrackingRoute.NONPROFIT, nonprofit.name);
  }, [nonprofit.name]);

  const { donationToJoinId, userToJoinId } = useJoinDonationParams();
  const { displayFGC, FGCDepleted } = useFallGivingChallengeActive(
    nonprofit.id
  );
  const specialHeaderContent =
    displayFGC && !FGCDepleted ? (
      <FGCNonprofitHeader />
    ) : nonprofitAnnouncement ? (
      <NonprofitAnnouncement css={largeScreenAnnouncementCss}>
        {nonprofitAnnouncement}
      </NonprofitAnnouncement>
    ) : null;
  const nonprofitsState = useContext(NonprofitsContext);

  // const myDonations = useContext(MyDonationsContext);
  //
  // const loggedInUserDonated = useMemo(
  //   () =>
  //     [...myDonations.donationsById.values()].find(
  //       ({ toNonprofitId }) => (nonprofit.id === toNonprofitId)
  //     ),
  //   [myDonations.donationsById, nonprofit]
  // );

  if (openDonatePage && (!nonprofit.donationsEnabled || nonprofit.archived)) {
    return (
      <Redirect
        to={getRoutePath({
          format: URLFormat.RELATIVE,
          name: ClientRouteName.NONPROFIT_OR_CAUSE,
          tokens: { nonprofitSlug: nonprofit.primarySlug },
        })}
      />
    );
  }

  const navigateToNonprofitPage = () => {
    push(
      getRoutePath({
        format: URLFormat.RELATIVE,
        name: ClientRouteName.NONPROFIT_OR_CAUSE,
        tokens: { nonprofitSlug: nonprofit.primarySlug },
      })
    );
  };

  let donationTargetNonprofit = nonprofit;
  if (nonprofit.id === GIVEWELL_NONPROFIT_ID) {
    const givewellUnrestricted = getNonprofitOrUndefined(nonprofitsState, {
      id: GIVEWELL_UNRESTRICTED_NONPROFIT_ID,
    });
    if (givewellUnrestricted) {
      donationTargetNonprofit = givewellUnrestricted;
    }
  }

  const opengraphImageCloudinaryParams = nonprofitShareImageCloudinaryParams({
    logoCloudinaryId,
    coverImageCloudinaryId,
    imageHeight: OPENGRAPH_DIMENSIONS.height,
  });

  const metas = removeUndefinedOrNullValues({
    "og:description": description,
    "og:image":
      opengraphImageCloudinaryParams &&
      constructCloudinaryUrl({
        ...opengraphImageCloudinaryParams,
        dimensions: OPENGRAPH_DIMENSIONS,
      }),
  });

  if (openDonatePage && !isSSR) {
    return (
      <DonateV3Page
        donateAction={DonateModalAction.DONATE}
        nonprofit={donationTargetNonprofit}
        isOpen
        onRequestClose={navigateToNonprofitPage}
        donationToJoinId={donationToJoinId || undefined}
        userToJoinId={userToJoinId || undefined}
      />
    );
  }

  return (
    <DefaultPageLayout
      itemScope
      itemType={NONPROFIT_ITEM_TYPES[nonprofit.type]}
      pageTitle={name}
      metas={metas}
      canonical={getRoutePath({
        name: ClientRouteName.NONPROFIT_OR_CAUSE,
        tokens: { nonprofitSlug: nonprofit.primarySlug },
        format: URLFormat.ABSOLUTE,
      })}
      // Overlay the SignupIcentiveCampaign if the user is logged out
      headerContent={specialHeaderContent}
      headerContentCss={
        specialHeaderContent &&
        css`
          display: flex;
          align-items: center;
          background: var(${colorCssVars.background.faded});
        `
      }
      pageContentCss={verticalStackCss.l}
    >
      <meta
        itemProp="url"
        content={getRoutePath({
          name: ClientRouteName.NONPROFIT_OR_CAUSE,
          tokens: { nonprofitSlug: nonprofit.primarySlug },
          format: URLFormat.ABSOLUTE,
        })}
      />
      <PageSection css={{ paddingTop: spacing.l }} ref={headerDonateBlockRef}>
        <NonprofitPageV3HeaderCard
          nonprofit={nonprofit}
          nonprofitMatchingCampaign={nonprofitMatchingCampaign}
        />
      </PageSection>
      <PageSection
        contentCss={pageGridCss(hideFundraisers && !showFundSection)}
      >
        <div css={verticalStackCss.l}>
          <NonprofitPageV3AboutCard nonprofit={nonprofit} />
          <div
            css={css`
              display: none;
              ${cssForMediaSize({
                min: MediaSize.SMALL,
                css: css`
                  display: block;
                `,
              })}
            `}
          >
            <ParentNonprofitNote nonprofit={nonprofit} />
          </div>
          {!nonprofit.hasAdmin && <BecomeAnAdminCard nonprofit={nonprofit} />}
        </div>
        <div css={verticalStackCss.l}>
          {/*
           TODO: test this in a separate a/b test
           loggedInUserDonated && <NonprofitPageV3YouAreSupporterCard />
           */}
          {initialFundraisers && (
            <NonprofitFundraisersList
              nonprofit={nonprofit}
              initialFundraisers={initialFundraisers}
            />
          )}
          <div
            css={css`
              ${cssForMediaSize({
                min: MediaSize.SMALL,
                css: css`
                  display: none;
                `,
              })}
            `}
          >
            <ParentNonprofitNote nonprofit={nonprofit} />
          </div>
          {createdFundIds && createdFundIds.length > 0 && (
            <NonprofitFeed nonprofitIds={createdFundIds} />
          )}
          {endorserNonprofitIds && endorserNonprofitIds.length > 0 && (
            <NonprofitSupportingFunds nonprofitIds={endorserNonprofitIds} />
          )}
          <NonprofitPageV3RecentContributorsCard
            nonprofit={nonprofit}
            nonprofitFeed={contributorList}
            hasMore={contributorListHasMore}
          />
        </div>
      </PageSection>
      {showMobileActionButtons &&
        nonprofit.displayType !== NonprofitDisplayType.DIRECTORY && (
          <MobileActionButtonsContainer
            headerDonateBlockRef={headerDonateBlockRef}
          >
            <div css={verticalStackCss.xs}>
              <div
                css={[
                  horizontalStackCss.s,
                  css`
                    align-items: stretch;
                    > * {
                      display: flex;
                      flex-basis: 100%;
                    }
                  `,
                ]}
              >
                <ShareButton
                  aria-label="Share nonprofit"
                  data-tname="nonprofitShareButton"
                  role={ButtonRole.SECONDARY}
                  hideText
                  shareData={(medium) =>
                    getSupportNonprofitShareData({
                      medium,
                      nonprofit,
                      body: nonprofit?.description || undefined,
                      url: getRoutePath({
                        name: ClientRouteName.NONPROFIT_OR_CAUSE,
                        tokens: { nonprofitSlug: nonprofit.primarySlug },
                        format: URLFormat.ABSOLUTE,
                      }),
                    })
                  }
                  instagramImageCloudinaryParams={
                    nonprofitShareImageCloudinaryParams({
                      logoCloudinaryId: nonprofit.logoCloudinaryId,
                      coverImageCloudinaryId: nonprofit.coverImageCloudinaryId,
                      imageHeight: INSTAGRAM_POST_DIMENSIONS.height,
                    }) || DEFAULT_SHARE_IMAGE_CLOUDINARY_PARAMS
                  }
                  buttonCss={[
                    fullWidthButtonCss,
                    css`
                      background-color: ${colorPalette.white};
                    `,
                  ]}
                >
                  Share
                </ShareButton>
                <DonateButton
                  data-tname="startDonateButton"
                  donationsEnabled={nonprofit.donationsEnabled}
                  primarySlug={nonprofit.primarySlug}
                  size={ButtonSize.MEDIUM}
                  toNonprofitPage
                />
              </div>
              {nonprofitAnnouncement && (
                <strong css={smallScreenAnnouncementCss}>
                  {nonprofitAnnouncement}
                </strong>
              )}
            </div>
          </MobileActionButtonsContainer>
        )}
    </DefaultPageLayout>
  );
}
