import { configureScope as configureSentryScope } from "@sentry/react";
import { Auth0Error, AuthorizeOptions } from "auth0-js";
import { BooleanFromString } from "io-ts-types/BooleanFromString";
import Cookies from "js-cookie";

import {
  SESSION_MAX_AGE_DAYS,
  sessionExistsCookieCodec,
  frontendAuthStateCodec,
  IdentityProvider,
} from "@every.org/common/src/codecs/auth";
import {
  SharedDonationData,
  landingPageCodec,
} from "@every.org/common/src/codecs/cookies";
import {
  EntityName,
  PersonalDonationResponse,
} from "@every.org/common/src/codecs/entities";
import {
  AccountStatus,
  DonationVisibility,
} from "@every.org/common/src/entity/types";
import { CookieKey } from "@every.org/common/src/entity/types/cookies";
import { generateNonce } from "@every.org/common/src/helpers/auth";
import { assertEnvPresent } from "@every.org/common/src/helpers/getEnv";
import { removeUndefinedValues } from "@every.org/common/src/helpers/objectUtilities";
import { isRelativeUrl } from "@every.org/common/src/helpers/url";

import { dispatchAuthState } from "src/context/AuthContext/";
import { getWebAuth } from "src/context/AuthContext/WebAuth";
import { AuthStatus, AuthContextUser } from "src/context/AuthContext/types";
import { dispatchNonprofitsAction } from "src/context/NonprofitsContext";
import {
  ContextNonprofit,
  NonprofitActionType,
} from "src/context/NonprofitsContext/types";
import { dispatchUsersAction } from "src/context/UsersContext";
import { UsersActionType } from "src/context/UsersContext/types";
import {
  getTestingIdFromCookie,
  resetTestingIdInCookie,
} from "src/utility/abtesting";
// this is a hard cyclical dependency to resolve - but it isn't causing errors
// for now, so living with it
// eslint-disable-next-line import/no-cycle
import { LoginMethod, trackLoginAttempt } from "src/utility/analytics";
import { getCsrfToken } from "src/utility/apiClient";
import { getAuth0ErrorMessage } from "src/utility/auth0";
import { getDataFromCookie, setCookie } from "src/utility/cookies";
import { Globals } from "src/utility/globals";
import { getLocalStorage } from "src/utility/localStorage";
import { logger } from "src/utility/logger";
import { isMissingRequiredUserFields } from "src/utility/user";

const FGC_BANNER_STORAGE_KEY = "fallGivingChallangeBanner";
const COOKIE_DOMAIN = assertEnvPresent(
  process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
  "NEXT_PUBLIC_COOKIE_DOMAIN"
);
const API_ORIGIN = assertEnvPresent(
  process.env.NEXT_PUBLIC_API_ORIGIN,
  "NEXT_PUBLIC_API_ORIGIN"
);
const localStorage = getLocalStorage();

export function setSignupSharedDonationCookie(params: {
  donation: SharedDonationData;
}) {
  setCookie(CookieKey.SIGNUP_SHARED_DONATION, JSON.stringify(params.donation), {
    expires: 30,
    domain: COOKIE_DOMAIN,
  });
}

export function setSignupNonprofitAdminRequestCookie(params: {
  nonprofitId: ContextNonprofit["id"];
}) {
  setCookie(
    CookieKey.SIGNUP_ADMIN_REQUEST_NONPROFIT_ID,
    JSON.stringify(params.nonprofitId),
    {
      expires: 7,
      domain: COOKIE_DOMAIN,
    }
  );
}

export function getLandingPageFromCookie() {
  return getDataFromCookie(CookieKey.LANDING_PAGE, landingPageCodec);
}

/**
 * Triggers login logic on our API; we don't call it directly, we instead have Auth0 authenticate
 * the user via their chosen login method, and then Auth0 forwards the request to our API endpoint
 * for us
 */
const getLoginApiEndpointUrl = () => `${API_ORIGIN}/auth/login`;

/**
 * Triggers link account logic on our API; we don't call it directly, we instead
 * have Auth0 prepare the link operation by having the user log in via a new
 * login method, and then Auth0 forwards the request to our API endpoint for us
 */
const getLinkAccountApiEndpointUrl = () => `${API_ORIGIN}/auth/link`;

/**
 * Trigger logouts by navigating to this page; it will delete the user's session
 * and redirect the user home
 */
export const getLogoutApiEndpointUrl = () => `${API_ORIGIN}/auth/logout`;

/**
 * Saves cookies and local storage data necessary to send and save data during
 * the auth flow.
 *
 * - Generates and stores a nonce in the cookie to identify the login attempt
 *   and serve as CSRF protection; and stores the `redirectUrl` locally if
 *   present and valid
 * - saves invite data in the cookie if present, to allow the backend to
 *   associate the login attempt with a signup invite
 *
 * @param params.invite Since social signups go through the login path, if
 * present, sends the invite data through the Auth0 state param to connect the
 * social account to a signup incentive
 */
async function prepareLoginAttempt(params: {
  redirectUrl: string | null | undefined;
  idp: IdentityProvider;
  guestToken?: string;
  condensedSignUpFlow?: boolean;
  skipWelcomeModal?: boolean;
  loginAfterDonationFlow?: boolean;
}) {
  const stateCsrfToken = await generateNonce();
  const {
    redirectUrl,
    idp,
    condensedSignUpFlow,
    skipWelcomeModal,
    loginAfterDonationFlow,
  } = params;
  if (redirectUrl) {
    // check end-to-end
    if (!isValidLoginRedirectUrl({ url: redirectUrl })) {
      logger.warn({
        message: `Before login, invalid redirect URL present`,
        data: { redirectUrl, idp },
      });
    } else {
      localStorage?.setItem(
        getAuthRedirectKey({ nonce: stateCsrfToken }),
        redirectUrl
      );
    }
  }

  if (condensedSignUpFlow !== undefined) {
    localStorage?.setItem(
      getCondensedSignUpKey({ nonce: stateCsrfToken }),
      BooleanFromString.encode(condensedSignUpFlow)
    );
  }

  if (skipWelcomeModal !== undefined) {
    localStorage?.setItem(
      getSkipWelcomeModalKey({ nonce: stateCsrfToken }),
      BooleanFromString.encode(skipWelcomeModal)
    );
  }

  if (loginAfterDonationFlow !== undefined) {
    localStorage?.setItem(
      getloginAfterDonationFlowKey({ nonce: stateCsrfToken }),
      BooleanFromString.encode(loginAfterDonationFlow)
    );
  }

  return frontendAuthStateCodec.encode(
    removeUndefinedValues({
      csrfToken: await getCsrfToken(),
      frontendStateCsrfToken: stateCsrfToken,
      guestToken: params.guestToken,
      condensedSignUpFlow,
      skipWelcomeModal,
      loginAfterDonationFlow,
    })
  );
}

/**
 * Creates local storage key to enable redirecting after successful auth
 */
export function getAuthRedirectKey(params: { nonce: string }): string {
  return `authRedirect--${params.nonce}`;
}

/**
 * Creates local storage key to control using condensed sign up after auth
 */
export function getCondensedSignUpKey(params: { nonce: string }): string {
  return `condensedSignUpFlow--${params.nonce}`;
}

/**
 * Creates local storage key to consolidate users with same email
 */
export function getloginAfterDonationFlowKey(params: {
  nonce: string;
}): string {
  return `donationLogin--${params.nonce}`;
}

/**
 * Creates local storage key to control using condensed sign up after auth
 */
export function getSkipWelcomeModalKey(params: { nonce: string }): string {
  return `skipWelcomeModal--${params.nonce}`;
}

/**
 * Restricts where login redirects are permitted to point to
 */
export function isValidLoginRedirectUrl({ url }: { url: string }): boolean {
  // for now local to our app is the only restriction, but TODO: consider if
  // there are other URLs we shouldn't allow redirecting to
  return isRelativeUrl(url);
}

interface EmailLoginArgs {
  email: string;
  password: string;
  /**
   * Where to redirect to after login
   * @see isValidLoginRedirectUrl for redirect URL requirements
   */
  redirectUrl: string | null | undefined; // required but may be omitted, to force client code to pass it
  condensedSignUpFlow?: boolean;
  skipWelcomeModal?: boolean;
  loginAfterDonationFlow?: boolean;
}

/**
 * Result of a login attempt via email/password combo
 */
export enum EmailAuth0LoginStatus {
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

function getUsernamePasswordAuth0LoginParams({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  return {
    realm: "Username-Password-Authentication",
    email,
    password,
    responseType: "token id_token",
    responseMode: "form_post",
    redirectUri: getLoginApiEndpointUrl(),
  };
}

/**
 * Initiates a login attempt via an email/password combo
 *
 * DETAILED FLOW
 *
 * - user goes to login or signup page on website and initiates a login via
 *   username/password (the `auth0` Identity Provider (IdP))
 * - browser POST's Auth0's `/authenticate` endpoint with the username/password
 *   combo in the body (this is NOT the `/authorize` endpoint; it's Auth0's IdP
 *   for username/password logins)
 * - if correct, browser hits the Auth0 `/authorize` endpoint, which redirects
 *   browser to our API's `/auth/login` endpoint with Auth0 account data in the
 *   body
 * - @see loginRoute in the API's auth routes file for details on what happens
 *   in the backend (note: it involves some more redirects in the browser)
 * - Once authentication is complete, this function's returned Promise resolves
 *   and the UI can react accordingly
 */
export async function emailAuth0Login({
  email,
  password,
  redirectUrl,
  condensedSignUpFlow,
  skipWelcomeModal,
  loginAfterDonationFlow,
}: EmailLoginArgs): Promise<
  | { status: EmailAuth0LoginStatus.SUCCESS }
  | {
      status: EmailAuth0LoginStatus.ERROR;
      error: Auth0Error;
      errorMessage: string;
    }
> {
  const [webAuth, loginParams, state] = await Promise.all([
    getWebAuth(),
    getUsernamePasswordAuth0LoginParams({
      email,
      password,
    }),
    prepareLoginAttempt({
      redirectUrl,
      idp: IdentityProvider.USERNAME_PASSWORD,
      condensedSignUpFlow,
      skipWelcomeModal,
      loginAfterDonationFlow,
    }),
  ]);
  return new Promise((resolve) => {
    webAuth.login({ ...loginParams, state }, (error) => {
      if (!error) {
        resolve({ status: EmailAuth0LoginStatus.SUCCESS });
        return;
      }
      resolve({
        status: EmailAuth0LoginStatus.ERROR,
        error,
        errorMessage: getAuth0ErrorMessage(error),
      });
    });
  });
}

interface SocialLoginArgs {
  /**
   * Where to redirect to after login
   * @see isValidLoginRedirectUrl for redirect URL requirements
   */
  redirectUrl: string | null | undefined; // required but may be omitted, to force client code to pass it
  /**
   * If provided, guest user token to associate with the social login account
   */
  guestToken?: string;

  idp: IdentityProvider;

  condensedSignUpFlow?: boolean;
  skipWelcomeModal?: boolean;
  loginAfterDonationFlow?: boolean;
}

/**
 * Gets Auth0 login params to use when logging in with a social idP like Facebook/Google.
 * @param connection idP connection name
 */
function getSocialAuth0LoginParams(
  connection: IdentityProvider
): AuthorizeOptions {
  return {
    connection,
    responseType: "token",
    responseMode: "form_post",
    redirectUri: getLoginApiEndpointUrl(),
  };
}

/**
 * Initiates a login attempt via Facebook/Google as an Identity Provider
 *
 * DETAILED FLOW
 *
 * - user goes to login or signup page on website and initiates a login via
 *   and Identity Provider (IdP)) like Facebook/Google.
 * - relevant cookies and local state are saved - @see prepareLoginAttempt for
 *   details
 * - browser hits Auth0's `/authorize` endpoint to initiate the login
 * - Auth0 redirects browser to IdP to authenticate, which redirects to
 *   Auth0's `/login/callback` endpoint
 * - if successful, Auth0 redirects browser to our API's `/auth/login` endpoint
 *   with data necessary to complete login in the body
 * - @see loginRoute in the API's auth routes file for details on what happens
 *   in the backend (note: it involves some more redirects in the browser)
 * - Once authentication is complete, this function's returned Promise resolves
 *   and the UI can react accordingly
 */
export async function socialAuth0Login({
  redirectUrl,
  idp,
  guestToken,
  condensedSignUpFlow,
  skipWelcomeModal,
  loginAfterDonationFlow,
}: SocialLoginArgs) {
  const [webAuth, loginParams, state] = await Promise.all([
    getWebAuth(),
    getSocialAuth0LoginParams(idp),
    prepareLoginAttempt({
      redirectUrl,
      idp,
      guestToken,
      condensedSignUpFlow,
      skipWelcomeModal,
      loginAfterDonationFlow,
    }),
  ]);
  trackLoginAttempt(LoginMethod.SOCIAL, idp);
  webAuth.authorize({ ...loginParams, state });
}

type LinkParams =
  | { idp: IdentityProvider.FACEBOOK; redirectUrl: string | null | undefined }
  | { idp: IdentityProvider.GOOGLE; redirectUrl: string | null | undefined }
  | {
      idp: IdentityProvider.USERNAME_PASSWORD;
      email: string;
      password: string;
      redirectUrl: string | null | undefined;
    };

/**
 * Initiate linking an identify provider (IdP) with an existing account.
 * This allows the user to log in to the same account from multiple IdPs.
 *
 * This does the same thing as the Facebook login route, except rather than
 * redirecting to `/auth/login` at the end, it redirects to `/auth/link`, and
 * we explicitly request the Auth0 scope to link accounts.
 *
 * @see socialAuth0Login for details for details
 */
export async function linkAuth0Login(props: LinkParams) {
  const { idp, redirectUrl } = props;
  if (props.idp === IdentityProvider.USERNAME_PASSWORD) {
    const [webAuth, loginParams, state] = await Promise.all([
      getWebAuth(),
      getUsernamePasswordAuth0LoginParams({
        email: props.email,
        password: props.password,
      }),
      prepareLoginAttempt({
        redirectUrl,
        idp,
      }),
    ]);
    webAuth.login(
      {
        ...loginParams,
        redirectUri: getLinkAccountApiEndpointUrl(),
        scope: "update:current_user_identities",
        state,
      },
      (error) => {
        // WebAuth#login requires an error callback
        throw error;
      }
    );
  } else {
    const [webAuth, loginParams, state] = await Promise.all([
      getWebAuth(),
      getSocialAuth0LoginParams(idp),
      prepareLoginAttempt({
        redirectUrl,
        idp,
      }),
    ]);
    webAuth.authorize({
      ...loginParams,
      redirectUri: getLinkAccountApiEndpointUrl(),
      scope: "update:current_user_identities",
      state,
    });
  }
}

function clearLocalStorageSession() {
  if (!localStorage) {
    return;
  }
  for (const key of Object.keys(localStorage)) {
    if (key !== FGC_BANNER_STORAGE_KEY) {
      localStorage.removeItem(key);
    }
  }
}

export function logoutAuthState() {
  dispatchAuthState({
    user: undefined,
    guestUser: undefined,
    abTestingId: undefined,
    status: AuthStatus.LOGGED_OUT,
  });

  Cookies.remove(CookieKey.FORCE_LINK_IDP);
  Cookies.remove(CookieKey.SESSION_EXISTS);
  Cookies.remove(CookieKey.USER_STATUS);
  clearLocalStorageSession();
  Globals.everydotorgLoggedInUserId = undefined;
}

/*
 * Updates the AuthState
 *
 * Only pass the last donation frequency after a completed donation to keep it updated without calling the /me endpoint.
 *
 */
export function updateAuthState(
  user: AuthContextUser,
  lastDonation?: PersonalDonationResponse | undefined,
  lastDonationShareInfo?: boolean | undefined,
  lastDonationVisibility?: DonationVisibility | undefined,
  paidDonationCount?: number | undefined
) {
  if (!user) {
    Cookies.remove(CookieKey.USER_STATUS);
    return logoutAuthState();
  }

  const sessionExistsCookieData = Cookies.get(CookieKey.SESSION_EXISTS);
  if (!sessionExistsCookieData || sessionExistsCookieData === "exists") {
    setCookie(
      CookieKey.SESSION_EXISTS,
      sessionExistsCookieCodec.encode({ createdAt: new Date() }),
      {
        expires: SESSION_MAX_AGE_DAYS,
      }
    );
  }

  // Make sure ab testing id cookie and user.abTestingId match
  // Right now they might not match, in the future all should match
  // And we could in theory remove this
  const cookieId = getTestingIdFromCookie();
  if (cookieId !== user.abTestingId) {
    resetTestingIdInCookie(user.abTestingId);
  }

  const newSSOUserCookieData = Cookies.get(CookieKey.TRACK_NEW_SSO_USER);
  if (newSSOUserCookieData === user.id) {
    // We've created a new SSO user in the backend, and this is the first time they're logging in
    // Associate all previous anonymous actions to this new user, and all future actions as well
    Cookies.remove(CookieKey.TRACK_NEW_SSO_USER);
  }

  dispatchUsersAction({
    type: UsersActionType.ADD_USER,
    data: {
      ...user,
      entityName: EntityName.USER,
    },
  });
  Globals.everydotorgLoggedInUserId = user.id;
  configureSentryScope((scope) => {
    scope.setUser({ id: user.id, username: user.username });
  });
  if (user.entityName === EntityName.USER_PERSONAL) {
    // Put some status info into a cookie so that Middleware can know it without hitting the API
    setCookie(
      CookieKey.USER_STATUS,
      JSON.stringify({
        emailVerified: user.isEmailVerified,
        deactivated: user.accountStatus === AccountStatus.DEACTIVATED,
        profileIncomplete: isMissingRequiredUserFields(user),
      }),
      {
        expires: 30,
      }
    );

    if (user.adminFor && user.adminFor.length > 0) {
      dispatchNonprofitsAction({
        type: NonprofitActionType.ADD_NONPROFITS,
        data: user.adminFor.map(({ nonprofit, nonprofitTags }) => {
          return { ...nonprofit, nonprofitTags };
        }),
      });
    }
    return dispatchAuthState({
      user: {
        ...user,
        ...(lastDonation ? { lastDonation } : {}),
        ...(lastDonationShareInfo !== undefined
          ? { lastDonationShareInfo }
          : {}),
        ...(lastDonationVisibility ? { lastDonationVisibility } : {}),
        ...(paidDonationCount !== undefined ? { paidDonationCount } : {}),
      },
      guestUser: undefined,
      status: AuthStatus.LOGGED_IN,
      abTestingId: user?.abTestingId,
    });
  }
  Cookies.remove(CookieKey.USER_STATUS);
  return dispatchAuthState({
    user: undefined,
    guestUser: {
      ...user,
      ...(lastDonation ? { lastDonation } : {}),
      ...(lastDonationShareInfo !== undefined ? { lastDonationShareInfo } : {}),
      ...(paidDonationCount !== undefined ? { paidDonationCount } : {}),
    },
    abTestingId: user?.abTestingId,
    status: AuthStatus.LOGGED_OUT,
  });
}
