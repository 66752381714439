import { ButtonRole, ButtonSize, fullWidthButtonCss } from "@components/Button";
import { FundShareButton } from "@components/FundShareButton";
import { MAX_CARD_WIDTH_RAW } from "@components/ScrollingFeedLandingPage";
import { LikeButton } from "@components/feed/LikeButton";
import { DonateButton } from "@components/layout/DonateButton";
import { css } from "@emotion/react";
import React from "react";

import { LikeableType } from "@every.org/common/src/entity/types";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import {
  horizontalStackCss,
  spacing,
  verticalStackCss,
} from "src/theme/spacing";

export const BottomActions: React.FCC<{
  fund: ContextNonprofit;
}> = ({ fund }) => (
  <div
    css={[
      cssForMediaSize({
        max: MediaSize.X_SMALL,
        css: verticalStackCss.m,
      }),
      cssForMediaSize({
        min: MediaSize.SMALL,
        css: verticalStackCss.l,
      }),
      css`
        margin: ${spacing.xxxl} auto;
        max-width: ${MAX_CARD_WIDTH_RAW}px;
      `,
    ]}
  >
    <div
      css={[
        cssForMediaSize({
          max: MediaSize.X_SMALL,
          css: verticalStackCss.m,
        }),
        cssForMediaSize({
          min: MediaSize.SMALL,
          css: [
            horizontalStackCss.s,
            css`
              width: 100%;
              align-items: stretch;
              > * {
                display: flex;
                flex-basis: 50%;
              }
            `,
          ],
        }),
      ]}
    >
      <FundShareButton
        fund={fund}
        data-tname="footerShareFundButton"
        role={ButtonRole.SECONDARY}
        size={ButtonSize.MEDIUM}
      />
      <LikeButton
        data-tname="fundFooterLikeButton"
        role={ButtonRole.SECONDARY}
        size={ButtonSize.MEDIUM}
        type={LikeableType.FUND}
        id={fund.id}
        likeCount={fund.likesInfo?.count || null}
        loggedInUserLikes={fund.likesInfo?.hasLoggedInUserLiked || null}
      >
        Like
      </LikeButton>
    </div>
    {
      <DonateButton
        css={fullWidthButtonCss}
        data-tname="fundFooterStartDonateButton"
        primarySlug={fund.primarySlug}
        donationsEnabled={fund.donationsEnabled}
        size={ButtonSize.LARGE}
        toNonprofitPage
      >
        Donate
      </DonateButton>
    }
  </div>
);
