import {
  Button,
  ButtonRole,
  ButtonSize,
  ButtonTargetKind,
} from "@components/Button";
import React from "react";

import {
  ClientRouteName,
  URLFormat,
  getRoutePath,
} from "@every.org/common/src/helpers/clientRoutes";

export const StartFundraiserButton: React.FCC<{
  slug: string;
  donationsEnabled: boolean;
}> = ({ slug, donationsEnabled }) => {
  return (
    <Button
      data-tname="NonprofitAboutColumn--FundraiserButton"
      role={ButtonRole.SECONDARY}
      size={ButtonSize.MEDIUM}
      disabled={!donationsEnabled}
      onClick={{
        kind: ButtonTargetKind.LINK,
        to: getRoutePath({
          format: URLFormat.RELATIVE,
          name: ClientRouteName.FUNDRAISER_CREATE,
          tokens: { nonprofitSlug: slug },
        }),
      }}
    >
      Start a fundraiser
    </Button>
  );
};
